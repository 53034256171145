import ClientesRutas from '../modelos/ClientesRutas';

import {
    REQUEST_CLIENTES_RUTAS,
    REQUEST_CLIENTES_RUTAS_SUCCESS,
    REQUEST_CLIENTES_RUTAS_FAILED
} from '../constants/action_types';

const initialState = {
    all: [],
    selected: [],
    fetching: false,
    error: null,
    received_date: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_CLIENTES_RUTAS:
            return {
                ...state,
                fetching: true,
                error: null
            };
        case REQUEST_CLIENTES_RUTAS_SUCCESS:
            return {
                ...state,
                fetching: false,
                all: action.payload.data.map(d => new ClientesRutas(d.data)),
                received_date: Date.now()
            };
        case REQUEST_CLIENTES_RUTAS_FAILED:
            return {
                ...initialState,
                error: {
                    cause: action.payload
                }
            };
        default:
            return state;
    }
};
