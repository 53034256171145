// export const API_ADDRESS = 'https://api-sumaenlinea.ddns.net:81';
// export const API_ADDRESS = 'https://tapi2.sumaenlinea.mx';
export const API_ADDRESS = 'https://api.sumaenlinea.mx';

// TODO: En testing usar tapi y en producción usar la url buena

export const ENDPOINT_BITACORAS = `${API_ADDRESS}/bitacoras`;
export const ENDPOINT_OPERADORES = `${API_ADDRESS}/operadores`;
export const ENDPOINT_ADMINISTRATIVOS = `${API_ADDRESS}/colaboradoresAdm/lista`;
export const ENDPOINT_CLIENTES = `${API_ADDRESS}/clientes`;
export const ENDPOINT_ESTRUCTURAS = `${API_ADDRESS}/estructuras`;
export const ENDPOINT_PROVEEDORES = `${API_ADDRESS}/proveedores`;
export const ENDPOINT_RUTAS = `${API_ADDRESS}/rutas`;
export const ENDPOINT_TICKETS = `${API_ADDRESS}/tickets`;
export const ENDPOINT_TICKETS_ADMINISTRATIVOS = `${API_ADDRESS}/ticketsAdmin`;
export const ENDPOINT_TICKETS_ORDEN_COMPRA = `${API_ADDRESS}/tickets/ordenCompra`;
export const ENDPOINT_UNIDADES = `${API_ADDRESS}/unidades`;
export const ENDPOINT_CELULARES = `${API_ADDRESS}/equipos-celulares`;
export const ENDPOINT_MARCAS_CELULARES = `${API_ADDRESS}/marcasCelulares/`;
export const ENDPOINT_MODELOS_CELULARES = `${API_ADDRESS}/modelosCelulares/`;
export const ENDPOINT_LINEAS_CELULARES = `${API_ADDRESS}/lineasCelulares/`;
export const ENDPOINT_EQUIPOS_CELULARES = `${API_ADDRESS}/equiposCelulares/`;
export const ENDPOINT_ESTATUS_CELULARES = `${API_ADDRESS}/estatusCelulares/`;
export const ENDPOINT_STATUS_CELULARES = `${API_ADDRESS}/estatusCelulares/`;
export const ENDPOINT_PLANES_CELULARES = `${API_ADDRESS}/planesCelulares/`;
export const ENDPOINT_USUARIOS_CELULARES = `${API_ADDRESS}/usuariosCelulares/`;
export const ENDPOINT_COLABORADORES_CELULARES = `${API_ADDRESS}/colaboradores/`;
export const ENDPOINT_HISTORIAL_CELULARES = `${API_ADDRESS}/historicoCelulares/`;
export const ENDPOINT_COLABORADORES = `${API_ADDRESS}/operadores`;
export const ENDPOINT_CLIENTES_RUTAS = `${API_ADDRESS}/bitacoras/clientes/rutas/buscar`;
export const ENDPOINT_LOGIN = `${API_ADDRESS}/login`;
export const ENDPOINT_GASOLINAS = `${API_ADDRESS}/PrecioGasolinas/`;
export const ENDPOINT_GASOLINAS_HISTORICO = `${API_ADDRESS}/PrecioGasolinasHistorico/`;
export const ENDPOINT_REPORTE_PAGO_OPERADOR = `${API_ADDRESS}/reporte-pago-operador`