import {
    REQUEST_ADMINISTRATIVOS,
    REQUEST_ADMINISTRATIVOS_SUCCESS,
    REQUEST_ADMINISTRATIVOS_FAILED,
    INVALIDAR_ADMINISTRATUVOS
} from '../constants/action_types';

export const requestAdministrativos = (params) => ({
    type: REQUEST_ADMINISTRATIVOS,
    payload: params
});

export const onAdministrativosRequestSuccess = (response) => ({
    type: REQUEST_ADMINISTRATIVOS_SUCCESS,
    payload: response
});

export const onAdministratuvosRequestFailed = (response) => ({
    type: REQUEST_ADMINISTRATIVOS_FAILED,
    payload: response
});

export const invalidarAdministrativos = () => ({
    type: INVALIDAR_ADMINISTRATUVOS
});
