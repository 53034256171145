import { connect } from 'react-redux';

import {
    requestLogin
} from '../../creators/login';

import LoginForm from '../../components/Login/LoginForm';

const mapStateToProps = (state) => {
    const { login } = state;

    return { login };
};

const mapActionsToProps = {
    requestLogin
};

export default connect(mapStateToProps, mapActionsToProps)(LoginForm);
