import Administrativo from '../modelos/Administrativo';

import {
    REQUEST_ADMINISTRATIVOS,
    REQUEST_ADMINISTRATIVOS_SUCCESS,
    REQUEST_ADMINISTRATIVOS_FAILED
} from '../constants/action_types';

const initialState = {
    all: [],
    selected: [],
    fetching: false,
    error: null,
    received_date: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_ADMINISTRATIVOS:
            return {
                ...state,
                fetching: true,
                error: null
            };
        case REQUEST_ADMINISTRATIVOS_SUCCESS:
            return {
                ...state,
                fetching: false,
                all: action.payload.data.map(d => new Administrativo(d.data)),
                received_date: Date.now()
            };
        case REQUEST_ADMINISTRATIVOS_FAILED:
            return {
                ...initialState,
                error: {
                    cause: action.payload
                }
            };
        default:
            return state;
    }
};
