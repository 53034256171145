import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';

import { REQUEST_ADMINISTRATIVOS} from '../constants/action_types';
import { ENDPOINT_ADMINISTRATIVOS } from '../constants/endpoints';

import {
    onAdministrativosRequestSuccess,
    onAdministratuvosRequestFailed
} from '../creators/administrativos';

function * fetchAdministrativos() {
    try {
        const url = `${ENDPOINT_ADMINISTRATIVOS}`;

        const response = yield call(axios.post, url);

        yield put(onAdministrativosRequestSuccess(response));
    } catch (e) {
        yield put(onAdministratuvosRequestFailed(e.response));
    }
}

export function * watchRequestAdministrativos () {
    yield takeEvery(REQUEST_ADMINISTRATIVOS, fetchAdministrativos);
}
