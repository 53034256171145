import 'semantic-ui-css/semantic.min.css';
import { Container, Menu, Grid, Icon, Image, Item, Message } from 'semantic-ui-react';

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import moment from 'moment';

import LoginForm from './LoginForm/LoginFormContainer';
import AdministrarBitacoras from './AdministrarBitacoras/AdministrarBitacorasContainer';
import AdministrarTickets from './AdministrarTickets/AdministrarTicketsContainer';
import AdministrarEquiposCelulares from './AdministrarEquiposCelulares/AdministrarEquiposCelularesContainer';
import AdministrarColaboradores from './AdministrarColaboradores/AdministrarColaboradoresContainer';
import AdministrarClientesRutas from '../components/AdministrarClientesRutas/AdministrarClientesRutas';
import AdministrarPrecioGasolina from '../components/AdministrarPrecioGasolina/AdministrarPrecioGasolina'
import ReporteOperadores from '../components/ReportePagoOperador/ReporteOperadores'
import Home from '../components/Home/Home';

import logo from '../images/logo_suma_4.png';
import { REQUEST_LOGIN_SUCCESS } from '../constants/action_types';
import { API_ADDRESS } from '../constants/endpoints';

class AppContainer extends Component {
  constructor(props) {
    super(props);

    this.testEo = this.testEo.bind(this);
  }

  componentDidUpdate() {
    const usuario = localStorage.getItem('usuario');

    if (usuario) {
      this.props.store.dispatch({
        type: REQUEST_LOGIN_SUCCESS,
        payload: {
          data: JSON.parse(usuario)
        }
      });
    }
  }

  testEo() {
    this.forceUpdate();
  }

  logout() {
    localStorage.removeItem('usuario');
    window.location.href = '/';
  }

  render() {
    const { store } = this.props;
    const usuario = store.getState().login.user;
    const debug = false;
    let usuarioGeneralValido = ''

    const tipoUsuario = JSON.parse(localStorage.getItem("usuario"));
    if(tipoUsuario == undefined){
      usuarioGeneralValido = false
    }else{
      usuarioGeneralValido = true
    }

    /* Cerrando sesión al cambio de fecha */
    const endTimeLogin = setInterval(function () {
      const fechaLocalStorage = JSON.parse(localStorage.getItem('usuario'));
      const fechaLogin = fechaLocalStorage.fechaLogin;
      const validacionFecha = moment(fechaLogin).isSame(moment().format('YYYY-MM-DD'));

      if (validacionFecha === false) {
        localStorage.removeItem('usuario');
        window.location.href = '/';
      }
    }, 2700000);

    if (localStorage.getItem('usuario') === null) {
      clearInterval(endTimeLogin);
    }

    return (
      <Provider store={store}>
        <Router>
          {
            usuario || debug
              ? <Grid centered padded>
                <Grid.Row stretched columns={1}>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <Menu secondary compact fluid>
                      <Menu.Item key='logo' position='left' style={{ margin: '0' }}>
                        <Image src={logo} size='tiny' />
                      </Menu.Item>
                      <Menu.Item key='admin'>
                        <Item
                          as='h2'
                          style={{ fontWeight: 'bold', fontFamily: 'sans-serif', color: '#5E6867' }}
                        >Panel Admin
                        </Item>
                      </Menu.Item>
                      {
                        API_ADDRESS.includes('tapi') &&
                        <Menu.Item
                          key='testing' style={{ color: 'red', fontWeight: '700', position: 'unset' }}
                        >
                          <Icon name='cogs' />Testing
                        </Menu.Item>
                      }

                      <Menu.Item
                        key='produccion' position='right'
                        style={{ color: '#f2711c', fontWeight: '700', position: 'unset' }}
                      >
                        <span style={{ color: 'grey' }}>{usuario.iniciales}</span> &nbsp;
                        <Icon color='orange' name='user' />
                        <Icon
                          size='large' color='orange' name='log out'
                          style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => this.logout()}
                        />
                      </Menu.Item>
                    </Menu>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched columns={1}>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <Container fluid>
                      <Menu stackable>
                          <Menu.Item
                            to='/' as={NavLink} key='home'
                            onClick={this.handleItemClick} exact
                          >Inicio
                          </Menu.Item>
                          {tipoUsuario.permisos?.permisosGenerales &&
                            <>
                              <Menu.Item
                                to='/bitacoras' as={NavLink} key='bitacoras'
                                onClick={this.handleItemClick} exact
                              >Bitacoras
                              </Menu.Item>
                    
                              <Menu.Item
                                to='/tickets' as={NavLink} key='tickets'
                                onClick={this.handleItemClick} exact
                              >Tickets
                              </Menu.Item>
                    
                              <Menu.Item
                                to='/listado-colaboradores' as={NavLink} key='listado-colaboradores'
                                onClick={this.handleItemClick} exact
                              >Colaboradores
                              </Menu.Item>
                    
                              <Menu.Item
                                to='/clientes-rutas' as={NavLink} key='clientes-rutas'
                                onClick={this.handleItemClick} exact
                              >Clientes con rutas
                              </Menu.Item>
                    
                              <Menu.Item
                                to='/equipos-celulares' as={NavLink} key='equipos-celulares'
                                onClick={this.handleItemClick} exact
                              >Control de dispositivos
                              </Menu.Item>
                              <Menu.Item
                                to='/precio-gasolina' as={NavLink} key='precio-gasolina'
                                onClick={this.handleItemClick} exact
                              >Precio gasolina
                              </Menu.Item>
                              <Menu.Item
                                to='/reporte-pago-operador' as={NavLink} key='reporte-pago-operador'
                                onClick={this.handleItemClick} exact
                              >Reporte pago operador
                              </Menu.Item>
                            </>
                          }

                          {tipoUsuario.permisos?.permisosBasicosConPrecios && 
                            <>
                            <Menu.Item
                              to='/bitacoras' as={NavLink} key='bitacoras'
                              onClick={this.handleItemClick} exact
                            >Bitacoras
                            </Menu.Item>
                  
                            <Menu.Item
                              to='/tickets' as={NavLink} key='tickets'
                              onClick={this.handleItemClick} exact
                            >Tickets
                            </Menu.Item>
                  
                            <Menu.Item
                              to='/listado-colaboradores' as={NavLink} key='listado-colaboradores'
                              onClick={this.handleItemClick} exact
                            >Colaboradores
                            </Menu.Item>
                  
                            <Menu.Item
                              to='/clientes-rutas' as={NavLink} key='clientes-rutas'
                              onClick={this.handleItemClick} exact
                            >Clientes con rutas
                            </Menu.Item>
                
                            <Menu.Item
                              to='/precio-gasolina' as={NavLink} key='precio-gasolina'
                              onClick={this.handleItemClick} exact
                            >Precio gasolina
                            </Menu.Item>
                          </>
                          }
                          {tipoUsuario.permisos?.permisosBasicos && 
                            <>
                            <Menu.Item
                              to='/bitacoras' as={NavLink} key='bitacoras'
                              onClick={this.handleItemClick} exact
                            >Bitacoras
                            </Menu.Item>
                  
                            <Menu.Item
                              to='/tickets' as={NavLink} key='tickets'
                              onClick={this.handleItemClick} exact
                            >Tickets
                            </Menu.Item>
                  
                            <Menu.Item
                              to='/listado-colaboradores' as={NavLink} key='listado-colaboradores'
                              onClick={this.handleItemClick} exact
                            >Colaboradores
                            </Menu.Item>
                  
                            <Menu.Item
                              to='/clientes-rutas' as={NavLink} key='clientes-rutas'
                              onClick={this.handleItemClick} exact
                            >Clientes con rutas
                            </Menu.Item>
                            
                            { tipoUsuario.typeUser === "Talento humano" &&
                              <Menu.Item
                                to='/reporte-pago-operador' as={NavLink} key='reporte-pago-operador'
                                onClick={this.handleItemClick} exact
                              >Reporte pago operador
                              </Menu.Item>
                            }
                          </>
                          }
                          { tipoUsuario.permisos?.permisosMinimos && tipoUsuario.typeUser === "Auxiliar administrativo" &&
                            <>
                            <Menu.Item
                              to='/tickets' as={NavLink} key='tickets'
                              onClick={this.handleItemClick} exact
                            >Tickets
                            </Menu.Item>
                            <Menu.Item
                              to='/bitacoras' as={NavLink} key='bitacoras'
                              onClick={this.handleItemClick} exact
                            >Bitacoras
                            </Menu.Item>
                            <Menu.Item
                              to='/precio-gasolina' as={NavLink} key='precio-gasolina'
                              onClick={this.handleItemClick} exact
                            >Precio gasolina
                            </Menu.Item>
                            </>
                          }
                          { tipoUsuario.permisos?.permisosMinimos && tipoUsuario.typeUser === "Procesos" &&
                            <>
                            <Menu.Item
                              to='/bitacoras' as={NavLink} key='bitacoras'
                              onClick={this.handleItemClick} exact
                            >Bitacoras
                            </Menu.Item>
                            <Menu.Item
                                to='/reporte-pago-operador' as={NavLink} key='reporte-pago-operador'
                                onClick={this.handleItemClick} exact
                              >Reporte pago operador
                              </Menu.Item>
                            </>
                          }
                          { tipoUsuario.permisos?.permisosMinimos && tipoUsuario.typeUser === "Nominas" &&
                          <>
                            <Menu.Item
                              to='/bitacoras' as={NavLink} key='bitacoras'
                              onClick={this.handleItemClick} exact
                            >Bitacoras
                            </Menu.Item>
                            <Menu.Item
                              to='/reporte-pago-operador' as={NavLink} key='reporte-pago-operador'
                              onClick={this.handleItemClick} exact
                            >Reporte pago operador
                            </Menu.Item>
                          </>
                          }

                          { tipoUsuario.permisos?.permisosMinimos && tipoUsuario.typeUser === "Operaciones" &&
                          <>
                            <Menu.Item
                              to='/bitacoras' as={NavLink} key='bitacoras'
                              onClick={this.handleItemClick} exact
                            >Bitacoras
                            </Menu.Item>
                            <Menu.Item
                              to='/reporte-pago-operador' as={NavLink} key='reporte-pago-operador'
                              onClick={this.handleItemClick} exact
                            >Reporte pago operador
                            </Menu.Item>
                          </>
                          }
                          { tipoUsuario.permisos?.permisosMinimos && tipoUsuario.typeUser === "Hardware" &&
                            <>
                            <Menu.Item
                            to='/bitacoras' as={NavLink} key='bitacoras'
                            onClick={this.handleItemClick} exact
                            >Bitacoras
                            </Menu.Item>
                            <Menu.Item
                            to='/clientes-rutas' as={NavLink} key='clientes-rutas'
                            onClick={this.handleItemClick} exact
                            >Clientes con rutas
                            </Menu.Item>
                            </>
                          }
                          {/* { tipoUsuario.permisos.permisosMinimos && tipoUsuario.typeUser === "Reclutamiento" &&
                            <>
                            <Menu.Item
                            to='/bitacoras' as={NavLink} key='bitacoras'
                            onClick={this.handleItemClick} exact
                            >Bitacoras
                            </Menu.Item>
                            </>
                          } */}
                          
                          {tipoUsuario.typeUser === "General" &&
                          <Message info 
                            header='Usuario sin permiso en esta plataforma' 
                            content='Si requires revisar algún dato en ADMIN consulta con tu supervisor' 
                            style={{margin: '0.5rem'}}
                          />
                          }

                      </Menu>

                      <Route path='/' exact component={Home} />
                      <Route path='/bitacoras' component={AdministrarBitacoras} />
                      <Route path='/tickets' component={AdministrarTickets} />
                      <Route path='/equipos-celulares' component={AdministrarEquiposCelulares} />
                      <Route path='/listado-colaboradores' component={AdministrarColaboradores} />
                      <Route path='/clientes-rutas' component={AdministrarClientesRutas} />
                      <Route path='/precio-gasolina' component={AdministrarPrecioGasolina} />
                      <Route path='/reporte-pago-operador' component={ReporteOperadores} />
                    </Container>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              : <LoginForm onEo={this.testEo} />
          }
        </Router>
      </Provider>
    );
  }
}

AppContainer.propTypes = {
  store: PropTypes.object.isRequired
};

export default AppContainer;