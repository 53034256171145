import React, { Component } from 'react'
import { Segment, Table, Message } from 'semantic-ui-react'
import PropTypes from 'prop-types'

class ListadoClientesRutas extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: this.props
    }
  }

  render () {
    const { data } = this.props

    let idRutas = []
    let clienteRuta = ''

    if (data.success !== false) {
      data.data.map(ruta => {
        const rutaValidada = ruta.relations.rutas.data.attributes ? ruta.relations.rutas.data.attributes : null
        clienteRuta = ruta

        const filtrando = {}
        // eslint-disable-next-line no-return-assign
        idRutas = rutaValidada.filter((o) => filtrando[o.ID_UNIDAD] ? false : filtrando[o.ID_UNIDAD] = true)
        return (
          clienteRuta,
          idRutas
        )
      })
    }
    return (
      <Segment>
        {
          data.success !== false &&
            <Table celled padded striped textAlign='center'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ID Cliente</Table.HeaderCell>
                  <Table.HeaderCell>Cliente</Table.HeaderCell>
                  <Table.HeaderCell>Fecha</Table.HeaderCell>
                  <Table.HeaderCell>Id Unidad</Table.HeaderCell>
                  <Table.HeaderCell>Tipo de unidad</Table.HeaderCell>
                  <Table.HeaderCell>Id Ruta</Table.HeaderCell>
                  <Table.HeaderCell>Ruta</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {idRutas?.map(idRuta =>
                  <Table.Row key={idRuta.ID_UNIDAD}>
                    <Table.Cell singleLine> {clienteRuta.ID} </Table.Cell>
                    <Table.Cell> {clienteRuta.CLIENTE} </Table.Cell>
                    <Table.Cell> {idRuta.FECHA} </Table.Cell>
                    <Table.Cell> {idRuta.ID_UNIDAD} </Table.Cell>
                    <Table.Cell> {idRuta.DESCRIPCION} </Table.Cell>
                    <Table.Cell> {idRuta.ID_RUTA} </Table.Cell>
                    <Table.Cell> {idRuta.NOMBRE} </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>

            </Table>
        }

        {
          data.success === false &&
            <Message negative>
              <Message.Header>No encontramos información con estos datos</Message.Header>
              <p>Realice una nueva búsqueda ingresando todos los datos</p>
            </Message>
        }

      </Segment>
    )
  }
}

ListadoClientesRutas.propTypes = {
  data: PropTypes.object
}

export default ListadoClientesRutas
