import React, { Component } from "react";
import { Table, Icon } from "semantic-ui-react";

class ResultadosPorFechas extends Component {
    constructor(props){
        super(props)

        this.state = ({

        })
    }

  render() {
    const listaResultados = this.props.listaPorFechas;

    return (
      <Table celled structured>
        <Table.Header style={{position: 'sticky', top: '0', zIndex: '2'}}>
          <Table.Row>
                <Table.HeaderCell rowSpan='2' textAlign='center'>Id</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2' textAlign='center'>Operador</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2' textAlign='center'>Tipo viaje</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2' textAlign='center'>Ruta</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2' textAlign='center'>Tipo</Table.HeaderCell>
                <Table.HeaderCell colSpan='2' textAlign='center'>Kilometraje</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2' textAlign='center'>Tipo unidad</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2' textAlign='center'>No. viajes</Table.HeaderCell>
                <Table.HeaderCell colSpan='2' textAlign='center'>Pago chofer</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2' textAlign='center'>Sueldo garantía</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2' textAlign='center'>Días</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2' textAlign='center'>Domingo</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
                <Table.HeaderCell textAlign='center'>Unitario</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Total</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Unitario</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Total</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
        { listaResultados.map((item, index) => {
          return (
            <Table.Row key={index}>
              <Table.Cell>{item.ID_OPERADOR}</Table.Cell>
              <Table.Cell>{item.OPERADOR}</Table.Cell>
              <Table.Cell>{item.TIPO_VIAJE}</Table.Cell>
              <Table.Cell>{item.NOMBRE_RUTA}</Table.Cell>
              <Table.Cell textAlign='center'>{item.TIPO}</Table.Cell>
              <Table.Cell textAlign='center'>{item.KM_RUTA_UNITARIO}</Table.Cell>
              <Table.Cell textAlign='center'>{item.KM_TOTAL}</Table.Cell>
              <Table.Cell textAlign='center'>{item.UNIDAD_TIPO}</Table.Cell>
              <Table.Cell textAlign='center'>{item.NO_VIAJES}</Table.Cell>
              <Table.Cell textAlign='center'>{item.PAGO_CHOFER_UNITARIO}</Table.Cell>
              <Table.Cell textAlign='center'>{item.TOTAL_PAGO_CHOFER}</Table.Cell>
              <Table.Cell textAlign='center'>{item.SUELDO_GARANTIA}</Table.Cell>
              <Table.Cell textAlign='center'>{item.DIAS}</Table.Cell>
              <Table.Cell textAlign='center'>{item.DOMINGO> 0 ? <Icon color="green" name='check' />:<Icon color="red" name='x' />}</Table.Cell>
            </Table.Row>
          )
        })} 
        </Table.Body>
      </Table>
    );
  }
}

export default ResultadosPorFechas;