import React, { Component } from 'react'
import { Form, Container, Message, Grid, Button, Icon, Table } from 'semantic-ui-react'
import axios from 'axios';
import { 
    ENDPOINT_LINEAS_CELULARES, 
    ENDPOINT_PROVEEDORES,
    ENDPOINT_PLANES_CELULARES
} from '../../constants/endpoints';

import { extraerOpcionesProveedoresEquiposCelulares, extraerOpcionesPlanesEquiposCelulares,extraerOpcionesLineasCelulares } from '../../helpers/index';

class AdministrarLineas extends Component {
    constructor(props){
        super(props)

        this.initialValues = {
            registrar: false,
            editar: false,
            buscar: false,
            success: null
        }   

        this.state = {
            ...this.initialValues
        }
    }

    getProveedores () {
        const params= ['activo=true', 'id=506']
        const url = `${ENDPOINT_PROVEEDORES}` + (params.length ? `?${params.join('&')}` : '');

        axios.get(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        proveedores: response.data,
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    getLineas () {
        const url = `${ENDPOINT_LINEAS_CELULARES}` + 'lista';
        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        lineas: response.data.data,
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    getPlanes () {
        const url = `${ENDPOINT_PLANES_CELULARES}` + 'lista';

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        planes: response.data.data,
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    handleClickButton = (e, data) => {
        switch (data.id) {
            case 'registrar':
                this.getProveedores()
                this.getPlanes()
                this.setState({
                    ...this.initialValues,
                    registrar: true,
                })
                break;
            case 'buscar':
                this.getLineas ()
                this.setState({
                    ...this.initialValues,
                    buscar: true,
                })
                break;
            case 'editarInputs':
                this.getProveedores()
                this.getPlanes()
                this.setState({
                    openInputs: true,
                })
                break;
            case 'editar':
                this.setState({
                    editar: true,
                })
                break;
            default:
                this.setState({
                    ...this.initialValues,
                })
                break;
        }
    }

    onChange = (e, data) => {
        if (data) {
            this.setState({ [data.name]: data.value })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }

        if(data.name === 'lineaSeleccionada'){
            this.selectedLinea(data.value)
            this.setState({
                editar: true,
            })
        }
    }

    onSubmit = () => {

        const params = [];

        if(this.state.linea){
            params.push(`telefono=${this.state.linea}`)
        }

        if(this.state.planSeleccionado){
            params.push(`tipoPlanId=${this.state.planSeleccionado}`)
        }

        if(this.state.proveedorSeleccionado){
            params.push(`proveedorId=${this.state.proveedorSeleccionado}`)
        }

        const url = `${ENDPOINT_LINEAS_CELULARES}` + 'add' + (params.length ? `?${params.join('&')}` : '');

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        success: response.data.success,
                        message: response.data.message,
                        details: response.data.details
                    })

                    setTimeout(() => this.setState({...this.initialValues }), 3000);
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    selectedLinea = (id) => {
        const url = `${ENDPOINT_LINEAS_CELULARES}` + 'elemento' + `?id=${id}`;

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        linea: response.data.data
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    onEdit = () => {
        const params = [];

        if(this.state.nuevoNombre){
            params.push(`telefono=${this.state.nuevoNombre}`)
        }

        if(this.state.planSeleccionado){
            params.push(`tipoPlanId=${this.state.planSeleccionado}`)
        }

        if(this.state.proveedorSeleccionado){
            params.push(`proveedorId=${this.state.proveedorSeleccionado}`)
        }

        if(this.state.lineaSeleccionada){
            params.push(`id=${this.state.lineaSeleccionada}`)
        }

        const url = `${ENDPOINT_LINEAS_CELULARES}` + 'modificar' + (params.length ? `?${params.join('&')}` : '');

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        success: response.data.success,
                        message: response.data.message,
                        details: response.data.details
                    })

                    setTimeout(() => this.setState({...this.initialValues }), 3000);
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    render () {
        const { registros } = this.props
        const { proveedores, planes, linea, lineas, nuevoNombre, registrar, editar, buscar, openInputs, success, error, message, details } = this.state
        
        return(
            <>
                <Container>
                    <Grid columns={2} relaxed='very' stackable>
                        <Grid.Column>
                            {
                                registros.linea ?
                                <>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <Button id='registrar' color='green' onClick={this.handleClickButton} size='medium' style={{marginBottom: '10px'}}>Registrar línea</Button>
                                    <Button id='buscar' color='green' onClick={this.handleClickButton} size='medium'>Editar línea</Button>
                                </div>
                                </>:<></>
                            }
                        </Grid.Column>
                        <Grid.Column>
                        {
                               proveedores && planes && registrar ?
                                <>
                                <h3>Registrar Líneas</h3>
                                <Form form='formRegistrarLinea' onSubmit={this.onSubmit}>
                                    <Form.Input 
                                        label='Ingresa el número' 
                                        name='linea'
                                        type='number'
                                        value={this.state.linea}
                                        onChange={this.onChange}
                                        placeholder='Línea'
                                        required
                                    />
                                    <Form.Select
                                        name='proveedorSeleccionado' label='Selecciona el proveedor' fluid search selection
                                        options={proveedores.map(extraerOpcionesProveedoresEquiposCelulares)}
                                        value={proveedores.id}
                                        placeholder='Proveedor'
                                        onChange={this.onChange}
                                    />
                                    <Form.Select
                                        name='planSeleccionado' label='Selecciona el plan' fluid search selection
                                        options={planes.map(extraerOpcionesPlanesEquiposCelulares)}
                                        value={planes.ID}
                                        placeholder='Plan'
                                        onChange={this.onChange}
                                    />
                                    <Form.Field>
                                        <Form.Button id='guardar' color='orange'>Registrar</Form.Button>
                                    </Form.Field>
                                </Form></>:<></>
                            }
                            {
                                success == true && registrar ? (
                                    <Message positive>
                                        <Message.Header>Línea {linea}</Message.Header>
                                        <Message.Content>Registrada correctamente</Message.Content>
                                    </Message>
                                ):(
                                    <></>
                                )
                            }

                            {
                                success == false  && registrar ? (
                                    <Message error header={message} list={details}/>
                                ):(
                                <></> 
                                )
                            }

                            { lineas && buscar ? 
                                <>
                                 <h3>Editar Línea</h3>
                                 <Form form='formBuscarModelo'>
                                    <Form.Select
                                        name='lineaSeleccionada' label='Selecciona la línea a editar' fluid search selection
                                        options={lineas.map(extraerOpcionesLineasCelulares)}
                                        value={lineas.ID}
                                        placeholder='Línea'
                                        onChange={this.onChange}
                                    />
                                 </Form>
                                </>:<></>
                            }
                        </Grid.Column>
                        <Grid.Row>
                            
                        </Grid.Row>
                    </Grid>
                    {
                        linea && editar &&
                        <>
                        <div style={{margin: '3rem 0 0 0', display: 'flex', flexDirection: 'column'}}>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell textAlign='center'>Id</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Teléfono</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Plan</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Proveedor</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Acciones</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell singleLine textAlign='center'>{linea[0].ID}</Table.Cell>
                                        <Table.Cell singleLine textAlign='center'>{linea[0].TELEFONO}</Table.Cell>
                                        <Table.Cell singleLine textAlign='center'>{linea[0].NOMBRE_PLAN}</Table.Cell>
                                        <Table.Cell singleLine textAlign='center'>Telcel</Table.Cell>
                                        <Table.Cell singleLine textAlign='center'>
                                            <Icon name='edit' color="orange" size='large' id='editarInputs' onClick={this.handleClickButton}/>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>

                        {
                            openInputs && proveedores && planes && linea ?
                            <div style={{margin: '3rem 0 0 0'}}>
                                <Message info header='Todos los campos son obligatorios'
                                content="Si alguno de los campos no cambia, registralo con el dato anterior."
                                />
                                <Form form='formEditarLinea' onSubmit={this.onEdit}>
                                     <Form.Select
                                        name='proveedorSeleccionado' label='Selecciona el proveedor' fluid search selection
                                        options={proveedores.map(extraerOpcionesProveedoresEquiposCelulares)}
                                        value={proveedores.id}
                                        placeholder='Proveedor'
                                        onChange={this.onChange}
                                    />
                                    <Form.Select
                                        name='planSeleccionado' label='Selecciona el plan' fluid search selection
                                        options={planes.map(extraerOpcionesPlanesEquiposCelulares)}
                                        value={planes.ID}
                                        placeholder='Plan'
                                        onChange={this.onChange}
                                    />
                                    <Form.Input 
                                        label='Ingresa el nuevo número de la línea' 
                                        name='nuevoNombre'
                                        type='number'
                                        value={nuevoNombre}
                                        placeholder='Nueva línea'
                                        onChange={this.onChange}
                                        required
                                    />
                                    <Form.Field>
                                    <Form.Button id='editar' color='orange'
                                        onClick={this.handleClickButton}>Editar</Form.Button>
                                    </Form.Field>
                                </Form>
                            </div>:<></>
                        }
                                                       
                        {
                            success == true && editar ? (
                                <Message positive>
                                    <Message.Header>La línea {nuevoNombre}</Message.Header>
                                    <Message.Content>Editada correctamente</Message.Content>
                                </Message>
                            ):(
                                <></>
                            )
                        }

                        {
                            success == false && editar ? (
                                <Message error header={message} list={details}/>
                            ):(
                            <></> 
                            )
                        }

                        </>
                    }
                    {
                        error &&
                        <Message negative>
                            <Message.Header>Error ({error})</Message.Header>
                        {error}
                        </Message>
                    }
                </Container>
            </>
        )
    }
}

export default AdministrarLineas