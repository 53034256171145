import React, { Component } from 'react'
import { Message } from 'semantic-ui-react'

class Historial extends Component {
    constructor(props){
        super(props)

        this.initialValues = {
            success: null,
        }   

        this.state = {
            ...this.initialValues
        }
    }

    render () {
        const { cantidadRegistros } = this.props
        
        return (
            <>
                {
                    cantidadRegistros > 0 ?
                        <Message info>
                            <Message.Header>Descarga de archivo completa</Message.Header>
                            <p>{cantidadRegistros} registros en historial</p>
                        </Message>
                    : <></>
                }
            </>
        )
    }
}

export default Historial