import React, { Component } from 'react'
import axios from 'axios';
import { Form, Segment, Message, Container, Grid, Divider } from 'semantic-ui-react'
import Historial from './Historial'

import { ENDPOINT_GASOLINAS, ENDPOINT_GASOLINAS_HISTORICO } from '../../constants/endpoints';

class AdministrarPrecioGasolina extends Component {
    constructor(props) {
        super(props)
    
        this.initialValues = {
            show: false,
            success: null,
            message: null,
            details: null,
            magnaNuevo: '', 
            premiumNuevo: '', 
            dieselNuevo: '',
        }
    
        this.state = {
          ...this.initialValues
        }
    }

    componentDidMount() {
        this.getPrecios()
        this.verHistorial() 
    }

    getPrecios () {
        const url = `${ENDPOINT_GASOLINAS}` + 'lista';

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        fecha: response.data.data[0].updated_at,
                        magna: response.data.data[0].magna,
                        premium: response.data.data[0].premium,
                        diesel: response.data.data[0].diesel
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    verHistorial () {
        const url = `${ENDPOINT_GASOLINAS_HISTORICO}` + 'lista';

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        historial: response.data.data
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    onChange = (e, data) => {
        if (data) {
            this.setState({ [data.name]: data.value })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    onSubmit= () => {
        const params = [];
        const usuarioId = JSON.parse(localStorage.getItem('usuario'));

        if(this.state.magnaNuevo){
            params.push(`magna=${this.state.magnaNuevo}`)
        }

        if(this.state.premiumNuevo){
            params.push(`premium=${this.state.premiumNuevo}`)
        }

        if(this.state.dieselNuevo){
            params.push(`diesel=${this.state.dieselNuevo}`)
        }

        if(usuarioId){
            params.push(`usuario=${usuarioId.id}`)
        }
        
        const url = `${ENDPOINT_GASOLINAS}` + 'modificar' + (params.length ? `?${params.join('&')}` : '');

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        success: response.data.success,
                        magna: this.state.magnaNuevo,
                        premium: this.state.premiumNuevo,
                        diesel: this.state.dieselNuevo,
                    })
                    setTimeout(() => this.setState({success: null, ...this.initialValues }), 3000);
                    this.getPrecios()
                    this.verHistorial()
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    render () {
        const { fecha, magna, premium, diesel, magnaNuevo, premiumNuevo, dieselNuevo,
        success, message, details, historial } = this.state
        const fechaReguistro = new Date(fecha).toLocaleDateString('es-mx', { weekday:"long", day: "numeric", month:"short", year:"numeric"})

        return(
            <Container fluid style={{ padding: '10px' }}>
                <h2>Precio de gasolina</h2>
                <Segment color='orange'>
                    <Grid columns={4} stackable divided style={{padding: '0 5%'}}>
                        <Grid.Row>
                            <Grid.Column>
                                <h3>Última actualización</h3>
                                <h3 style={{color: '#797878'}}>
                                    {fechaReguistro.charAt(0).toUpperCase() + fechaReguistro.slice(1)}
                                </h3>
                            </Grid.Column>
                            <Grid.Column>
                                <h3>Magna</h3>
                                <h3 style={{color: '#797878', textAlign: 'center'}}>
                                    ${magna}
                                    {/* {magna ? '$' + magna : '$' + magnaNuevo} */}
                                </h3>
                            </Grid.Column>
                            <Grid.Column>
                                <h3>Premium</h3>
                                <h3 style={{color: '#797878', textAlign: 'center'}}>
                                    ${premium}
                                    {/* {premium ? '$' + premium : '$' + premiumNuevo} */}
                                </h3>
                            </Grid.Column>
                            <Grid.Column>
                                <h3>Diesel:</h3>
                                <h3 style={{color: '#797878', textAlign: 'center'}}>
                                    ${diesel}
                                    {/* {diesel ? '$' + diesel : '$' + dieselNuevo} */}
                                </h3>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider hidden />
                    <Divider />

                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h3>Cambiar precio máximo autrorizado</h3>
                            </Grid.Column>
                        </Grid.Row>
                        
                        <Form form='formEditarPrecioGasolinas' onSubmit={this.onSubmit} style={{width: '100%', padding: '0 10%'}}>
                            <Grid columns={4} stackable divided>
                                <Grid.Row>
                                    <Grid.Column> 
                                        <Form.Input 
                                            label='Gasolina Magna'
                                            type='number'
                                            name='magnaNuevo'
                                            value={magnaNuevo}
                                            placeholder='Magna'
                                            onChange={this.onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column> 
                                        <Form.Input 
                                            label='Gasolina Premium'
                                            type='number'
                                            name='premiumNuevo'
                                            value={premiumNuevo}
                                            placeholder='Premium'
                                            onChange={this.onChange}
                                        />  
                                    </Grid.Column>
                                    <Grid.Column> 
                                        <Form.Input 
                                            label='Diesel'
                                            type='number'
                                            name='dieselNuevo'
                                            value={dieselNuevo}
                                            placeholder='Diesel'
                                            onChange={this.onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1.5rem 0'}}>
                                        <Form.Field>
                                            <Form.Button id='guardar' color='orange'>Guardar precios</Form.Button>
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Grid>
                    <Divider hidden />
                    {
                        success === true ? (
                            <Message positive>
                                <Message.Header>Proceso terminado</Message.Header>
                                <Message.Content>Precios de gasolina actualizados correctamente</Message.Content>
                            </Message>
                        ):(
                            <></>
                        )
                    }

                    {
                        success === false ?  (
                            <Message error header={message} list={details}/>
                        ):(
                            <></> 
                        )
                    }
                    <Divider hidden />
                    {
                        historial ?
                        <Historial historial={historial}/>:<></>
                    }
                </Segment>
            </Container>
        )
    }
}

export default AdministrarPrecioGasolina