import moment from 'moment';
import React, { Component } from 'react';
import { Form, Button, Select, Segment, Message, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { extraerOpcionesOperadores, extraerOpcionesAdministrativos, extraerOpcionesProveedores } from '../../helpers'


class FormaEditarTicket extends Component {

  constructor(props){
    super(props);

    this.state = {
      fecha: moment().format('YYYY-MM-DD'),
      hora: '',
      unidad: '',
      operador: -1,
      administrativo: -1,
      gasolinera: -1,
      folio: '',
      kilometraje: '',
      tipo_combustible: -1,
      disel: '',
      magna: '',
      litros: '',
      precio: '',
      idPartida: '',
      datosValidos: ''
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onFinalizarEdicion = this.onFinalizarEdicion.bind(this);
  }

  componentDidMount(){
    const { ticket } = this.props;

    if (ticket) {
      this.setState({
        fecha: ticket.fecha.slice(0, 10),
        hora: ticket.fecha.slice(11),
        unidad: ticket.idUnidad,
        operador: ticket.idOperador,
        gasolinera: ticket.idGasolinera,
        folio: ticket.folio,
        kilometraje: ticket.kilometraje,
        tipo_combustible: ticket.tipoCombustible,
        litros: ticket.litros,
        precio: ticket.precioCombustible,
        idPartida: ticket.idPartida
      });
    }
  }

  onChangeValue(event, data){
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  onSubmit(event){
    event.preventDefault();

    const { ticket, onRequestCreateTicket, onRequestUpdateTicket, onFinalizarEdicionTicket } = this.props;
    const { tipo_combustible, operador, gasolinera, unidad, administrativo } = this.state;
    
    let params = {};

    if (tipo_combustible !== -1 && gasolinera !== -1 && unidad !== '') {
      if(operador !== -1){
        params = {
          fecha: `${this.state.fecha} ${this.state.hora}`,
          unidad: this.state.unidad,
          operador: this.state.operador,
          gasolinera: this.state.gasolinera,
          folio: this.state.folio,
          kilometraje: this.state.kilometraje,
          tipo_combustible: this.state.tipo_combustible,
          litros: this.state.litros,
          precio_combustible: this.state.precio,
          verificado: true,
          idPartida: this.state.idPartida
        };
      }else {
        this.setState({ tipo_combustible: -1 });
      }

      if(administrativo !== -1){
        params = {
          fecha: `${this.state.fecha} ${this.state.hora}`,
          unidad: this.state.unidad,
          administrativo: this.state.administrativo,
          gasolinera: this.state.gasolinera,
          folio: this.state.folio,
          kilometraje: this.state.kilometraje,
          tipo_combustible: this.state.tipo_combustible,
          litros: this.state.litros,
          precio_combustible: this.state.precio,
          verificado: true,
          idPartida: this.state.idPartida
        };
      }else {
        this.setState({ tipo_combustible: -1 });
      }

      if (ticket) {
        onRequestUpdateTicket(ticket.id, params);
      } else {
        params.via_captura = 1;
        onRequestCreateTicket(params);
      }
      onFinalizarEdicionTicket();
    } else {
      this.setState({ tipo_combustible: -1 });
    }
  }

  onFinalizarEdicion(event){
    event.preventDefault();

    this.props.onFinalizarEdicionTicket();
  }

  handleChange = (e, { value }) => this.setState({ value })

  render(){
    const {
      operadores,
      administrativos,
      proveedores
    } = this.props;

    const {
      fecha,
      hora,
      unidad,
      operador,
      administrativo,
      gasolinera,
      folio,
      kilometraje,
      tipo_combustible,
      litros,
      precio,
      idPartida,
      value
    } = this.state;

    const combustibleValido = tipo_combustible === -1;
    const operadorValido = operador === -1;
    const gasolineraValida = gasolinera === -1;

    return (
      <Segment>
        <Form form='forma-editar-ticket' onSubmit={this.onSubmit}>
          <Form.Group widths='five'>
            <Form.Input
              name='fecha' label='Fecha' type='date' value={fecha} onChange={this.onChangeValue}
              required disabled={idPartida > 0}
            />
            <Form.Input
              name='hora' label='Hora' type='time' value={hora}
              onChange={this.onChangeValue} required disabled={idPartida > 0}
            />
            <Form.Input
              name='unidad' label='Unidad' type='number' step='1' value={unidad}
              onChange={this.onChangeValue} required disabled={idPartida > 0}
            />

            <Form.Group style={{display: "flex", flexDirection: "column", margin: "0 1rem"}}>
              <Form.Field>
                <p style={{fontWeight: 'bold', textAlign: 'center'}}>Tipo de colabodador</p>
              </Form.Field>
              <Form.Field>
                <Checkbox
                  radio
                  label='Operador'
                  name='checkboxRadioGroup'
                  value='Operador'
                  checked={this.state.value === 'Operador'}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  radio
                  label='Administrativo'
                  name='checkboxRadioGroup'
                  value='Administrativo'
                  checked={this.state.value === 'Administrativo'}
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form.Group>
            {
              value === 'Operador' ?
              <Form.Field
                control={Select} fluid label='Operador' name='operador' search placeholder='Operador'
                options={operadores.all.map(extraerOpcionesOperadores)} loading={operadores.fetching}
                value={operador} onChange={this.onChangeValue} disabled={idPartida > 0}
              />:<></>
            }
            {
              value === 'Administrativo' ?
              <Form.Field
                control={Select} label='Administrativo' name='administrativo' search placeholder='Selecciona un administrativo'
                options={administrativos.all.map(extraerOpcionesAdministrativos)} loading={administrativos.fetching}
                value={administrativo} onChange={this.onChangeValue} required disabled={idPartida > 0}
              />:<></>
            }
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field
              control={Select} label='Gasolinera' name='gasolinera' basic search
              placeholder='Selecciona alguna gasolinera'
              options={proveedores.all.map(extraerOpcionesProveedores)}
              loading={proveedores.fetching} value={gasolinera} onChange={this.onChangeValue}
              required disabled={idPartida > 0}
            />
            <Form.Input
              name='folio' label='Folio' value={folio} onChange={this.onChangeValue} required
              disabled={idPartida > 0}
            />
            <Form.Input
              name='kilometraje' label='Kilometraje' type='number' step='1'
              value={kilometraje} onChange={this.onChangeValue} required
            />

            <Form.Input
              control={Select} name='tipo_combustible' label='Tipo combustible' basic search
              options={[
                { key: 0, text: 'Magna', value: 'Magna' },
                { key: 1, text: 'Diesel', value: 'Diesel' },
                { key: 2, text: 'Premium', value: 'Premium' }
              ]}
              value={tipo_combustible} onChange={this.onChangeValue} required disabled={idPartida > 0}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Input
              name='litros' label='Litros' type='number' step='any' value={litros}
              onChange={this.onChangeValue} required disabled={idPartida > 0}
            />
            <Form.Input
              name='precio' label='Precio' type='number' step='any' value={precio}
              onChange={this.onChangeValue} required disabled={idPartida > 0}
            />
            <Form.Field>
              <label>&nbsp;</label>
              <Button.Group widths={2}>
                <Button fluid onClick={this.onFinalizarEdicion}>Cancelar</Button>
                <Button fluid color='orange'>Guardar</Button>
              </Button.Group>
            </Form.Field>
          </Form.Group>
        </Form>
        {
          combustibleValido === true || operadorValido === true || gasolineraValida === true
            ? <Message
              info
              header='Todos los campos son obligatorios'
              content='Revisa que hayas ingresado todo los datos antes de dar click en guardar'
            />
            : <></>
        }
      </Segment>
    );
  }
}

FormaEditarTicket.propTypes = {
  ticket: PropTypes.object,
  onRequestCreateTicket: PropTypes.func.isRequired,
  onRequestUpdateTicket: PropTypes.func.isRequired,
  onFinalizarEdicionTicket: PropTypes.func.isRequired,
  operadores: PropTypes.object.isRequired,
  proveedores: PropTypes.object.isRequired
};

export default FormaEditarTicket
