import React, { Component } from 'react'
import { Form, Container, Message, Grid, Button, Icon, Table } from 'semantic-ui-react'
import axios from 'axios';
import { 
    ENDPOINT_MODELOS_CELULARES,
    ENDPOINT_EQUIPOS_CELULARES,
    ENDPOINT_ESTATUS_CELULARES
} from '../../constants/endpoints';

import { 
    extraerOpcionesModelosEquiposCelulares, 
    extraerOpcionesEstatusEquiposCelulares, 
    extraerOpcionesEquiposCelulares 
} from '../../helpers/index';

class AdministrarEquipos extends Component {
    constructor(props){
        super(props)

        this.initialValues = {
            registrar: false,
            editar: false,
            buscar: false,
            imei: '', 
            success: null
        }   

        this.state = {
            ...this.initialValues
        }
    }

    getModelos () {
        const url = `${ENDPOINT_MODELOS_CELULARES}` + 'lista';
        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        modelos: response.data.data,
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    getEstatus () {
        const url = `${ENDPOINT_ESTATUS_CELULARES}` + 'lista';

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        estatus: response.data.data,
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    getEquipos () {
        const url = `${ENDPOINT_EQUIPOS_CELULARES}` + 'lista';

        axios.post(url)
            .then(response => {
                if(response.status == 200) {
                    this.setState({
                        equipos: response.data.data
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    handleClickButton = (e, data) => {
        switch (data.id) {
            case 'registrar':
                this.getModelos()
                this.getEstatus()
                this.setState({
                    ...this.initialValues,
                    registrar: true,
                })
                break;
            case 'buscar':
                this.getEquipos ()
                this.setState({
                    ...this.initialValues,
                    buscar: true,
                })
                break;
            case 'editarInputs':
                this.getModelos()
                this.getEstatus()
                this.setState({
                    openInputs: true,
                })
                break;
            case 'editar':
                this.setState({
                    editar: true,
                })
                break;
            default:
                this.setState({
                    ...this.initialValues,
                })
                break;
        }
    }

    onChange = (e, data) => {
        if (data) {
            this.setState({ [data.name]: data.value })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }

        if(data.name === 'equipoSeleccionado'){
            this.selectedEquipo(data.value)
            this.setState({
                editar: true,
            })
        }
    }

    onSubmit = () => {
        const params = [];

        if(this.state.imei){
            params.push(`imei=${this.state.imei}`)
        }

        if(this.state.modeloSeleccionado){
            params.push(`modelo=${this.state.modeloSeleccionado}`)
        }

        if(this.state.estatusSeleccionado){
            params.push(`status=${this.state.estatusSeleccionado}`)
        }

        if(this.state.nota){
            params.push(`nota=${this.state.nota}`)
        }

        const url = `${ENDPOINT_EQUIPOS_CELULARES}` + 'add' + (params.length ? `?${params.join('&')}` : '');

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        success: response.data.success,
                        message: response.data.message,
                        details: response.data.details
                    })

                    setTimeout(() => this.setState({...this.initialValues }), 3000);
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    selectedEquipo = (id) => {
        const url = `${ENDPOINT_EQUIPOS_CELULARES}` + 'elemento' + `?id=${id}`;

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        equipo: response.data.data
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    onEdit = () => {
        const params = [];

        if(this.state.equipoSeleccionado){
            params.push(`id=${this.state.equipoSeleccionado}`)
        }

        if(this.state.modeloSeleccionado){
            params.push(`modelo=${this.state.modeloSeleccionado}`)
        }

        if(this.state.nuevoImei){
            params.push(`imei=${this.state.nuevoImei}`)
        }

        if(this.state.estatusSeleccionado){
            params.push(`status=${this.state.estatusSeleccionado}`)
        }

        if(this.state.nuevaNota){
            params.push(`nota=${this.state.nuevaNota}`)
        }

        const url = `${ENDPOINT_EQUIPOS_CELULARES}` + 'modificar' + (params.length ? `?${params.join('&')}` : '');

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        success: response.data.success,
                        message: response.data.message,
                        details: response.data.details
                    })

                    setTimeout(() => this.setState({...this.initialValues }), 3000);
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    render () {
        const { registros } = this.props
        const { modelos, estatus, imei, nuevoImei, equipo, equipos, nota, nuevaNota, registrar, editar, buscar, openInputs, success, error, message, details } = this.state

        return(
            <>
                <Container>
                    <Grid columns={2} relaxed='very' stackable>
                        <Grid.Column>
                            {
                                registros.equipo ?
                                <>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <Button id='registrar' color='green' onClick={this.handleClickButton} size='medium' style={{marginBottom: '10px'}}>Registrar equipo</Button>
                                    <Button id='buscar' color='green' onClick={this.handleClickButton} size='medium'>Editar equipo</Button>
                                </div>
                                </>:<></>
                            }
                        </Grid.Column>
                        <Grid.Column>
                        {
                               modelos && estatus && registrar ?
                                <>
                                <h3>Registrar Equipo</h3>
                                <Form form='formRegistrarEquipo' onSubmit={this.onSubmit}>
                                    <Form.Select
                                        name='modeloSeleccionado' label='Selecciona el modelo del equipo' fluid search selection
                                        options={modelos?.map(extraerOpcionesModelosEquiposCelulares)}
                                        value={modelos.ID}
                                        placeholder='Modelo'
                                        onChange={this.onChange}
                                    />
                                    <Form.Input 
                                        label='Ingresa el IMEI' 
                                        name='imei'
                                        type='number'
                                        value={this.state.imei}
                                        placeholder='IMEI'
                                        onChange={this.onChange}
                                        required
                                    />
                                    {
                                        estatus &&
                                        <Form.Select
                                        name='estatusSeleccionado' label='Selecciona las condiciones del equipo' fluid search selection
                                        options={estatus.map(extraerOpcionesEstatusEquiposCelulares)}
                                        value={estatus.ID}
                                        placeholder='Condiciones'
                                        onChange={this.onChange}
                                    />
                                    }
                                    
                                    <Form.TextArea name='nota' value={this.state.nota} onChange={this.onChange} label='Notas' placeholder='Notas del equipo celular' />
                                    <Form.Field>
                                        <Form.Button id='guardar' color='orange'>Registrar</Form.Button>
                                    </Form.Field>
                                </Form></>:<></>
                            }
                            {
                                success == true && registrar ? (
                                    <Message positive>
                                        <Message.Header>El equipo con IMEI {imei}</Message.Header>
                                        <Message.Content>Registrado correctamente</Message.Content>
                                    </Message>
                                ):(
                                    <></>
                                )
                            }

                            {
                                success == false  && registrar ? (
                                    <Message error header={message} list={details}/>
                                ):(
                                <></> 
                                )
                            }

                            { equipos && buscar ? 
                                <>
                                 <h3>Editar Equipo</h3>
                                 <Form form='formBuscarEquipo'>
                                    <Form.Select
                                        name='equipoSeleccionado' label='Selecciona el IMEI del equipo a editar' fluid search selection
                                        options={equipos.map(extraerOpcionesEquiposCelulares)}
                                        value={equipos.ID}
                                        placeholder='IMEI'
                                        onChange={this.onChange}
                                    />
                                 </Form>
                                </>:<></>
                            }
                        </Grid.Column>
                        <Grid.Row>
                            
                        </Grid.Row>
                    </Grid>
                    {
                        equipo && editar &&
                        <>
                        <div style={{margin: '3rem 0 0 0', display: 'flex', flexDirection: 'column'}}>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell textAlign='center'>Id</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>IMEI</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Estatus</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Modelo</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Nota</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Acciones</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell singleLine textAlign='center'>{equipo[0].ID}</Table.Cell>
                                        <Table.Cell singleLine textAlign='center'>{equipo[0].IMEI}</Table.Cell>
                                        <Table.Cell singleLine textAlign='center'>{equipo[0].ESTATUS_NOMBRE}</Table.Cell>
                                        <Table.Cell singleLine textAlign='center'>{equipo[0].MODELO_NOMBRE}</Table.Cell>
                                        <Table.Cell singleLine textAlign='center'>{equipo[0].NOTAS}</Table.Cell>
                                        <Table.Cell singleLine textAlign='center'>
                                            <Icon name='edit' color="orange" size='large' id='editarInputs' onClick={this.handleClickButton}/>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>

                        {
                            openInputs && modelos && estatus ?
                            <div style={{margin: '3rem 0 0 0'}}>
                                <Message info header='Todos los campos son obligatorios'
                                content="Si alguno de los campos no cambia, registralo con el dato anterior."
                                />
                                <Form form='formEditarEquipo' onSubmit={this.onEdit}>
                                    <Form.Select
                                        name='modeloSeleccionado' label='Selecciona el modelo del equipo' fluid search selection
                                        options={modelos?.map(extraerOpcionesModelosEquiposCelulares)}
                                        value={modelos.ID}
                                        placeholder='Modelo'
                                        onChange={this.onChange}
                                    />
                                    <Form.Input 
                                        label='Ingresa el nuevo IMEI' 
                                        name='nuevoImei'
                                        type='number'
                                        value={nuevoImei}
                                        placeholder='IMEI'
                                        onChange={this.onChange}
                                        required
                                    />
                                    {
                                        estatus &&
                                        <Form.Select
                                            name='estatusSeleccionado' label='Selecciona la condición del equipo' fluid search selection
                                            options={estatus.map(extraerOpcionesEstatusEquiposCelulares)}
                                            value={estatus.ID}
                                            placeholder='Nueva condición del equipo'
                                            onChange={this.onChange}
                                        />
                                    }
                                    
                                    <Form.TextArea name='nuevaNota' value={nuevaNota} onChange={this.onChange} label='Notas' placeholder='Notas del equipo celular' />
                                    
                                    <Form.Field>
                                        <Form.Button id='editar' color='orange'
                                        onClick={this.handleClickButton}>Editar</Form.Button>
                                    </Form.Field>
                                </Form>
                            </div>:<></>
                        }
                                                       
                        {
                            success == true && editar ? (
                                <Message positive>
                                    <Message.Header>El equipo con IMEI {nuevoImei}</Message.Header>
                                    <Message.Content>Editado correctamente</Message.Content>
                                </Message>
                            ):(
                                <></>
                            )
                        }

                        {
                            success == false && editar ? (
                                <Message error header={message} list={details}/>
                            ):(
                            <></> 
                            )
                        }

                        </>
                    }
                    {
                        error &&
                        <Message negative>
                            <Message.Header>Error ({error})</Message.Header>
                        {error}
                        </Message>
                    }
                </Container>
            </>
        )
    }
}

export default AdministrarEquipos