import {
    REQUEST_CLIENTES_RUTAS,
    REQUEST_CLIENTES_RUTAS_SUCCESS,
    REQUEST_CLIENTES_RUTAS_FAILED
} from '../constants/action_types';

export const requestClientesRutas = (params) => {
    return {
        type: REQUEST_CLIENTES_RUTAS,
        payload: { params }
    };
};

export const onRequestClientesRutasSuccess = (response) => {
    return {
        type: REQUEST_CLIENTES_RUTAS_SUCCESS,
        payload: response
    };
};

export const onRequestClientesRutasFailed = (response) => {
    return {
        type: REQUEST_CLIENTES_RUTAS_FAILED,
        payload: response
    };
};
