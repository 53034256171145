import React, { Component } from 'react';
import axios from 'axios';
import { Segment, Table, Message, Button, Icon, Popup } from 'semantic-ui-react';
import { table_header, roundToTwoDecimals, downloadExcel } from '../../helpers/index';
import PropTypes from 'prop-types';
import {ENDPOINT_TICKETS_ORDEN_COMPRA} from '../../constants/endpoints'

const header_titles = [
  'Id',
  'Colaborador',
  'Gasolinera',
  'Unidad',
  'Kilometraje',
  'Folio',
  'Fecha',
  'Combustible',
  'Litros',
  'Precio',
  'Monto',
  'Partida (P) y Compra (C)',
  'Vía captura',
  'Controles'
];

class ListadoTickets extends Component {

  constructor(props){
    super(props);
    this.state={

    };
  }

  getOrdenCompra = (id) => {
    const url = `${ENDPOINT_TICKETS_ORDEN_COMPRA}?idPartida=${id}`;
  
    axios.post(url)
        .then(response => {
          if(response.status === 200){
            this.setState({idCompra: response.data.attributes[0].ID,
              idPartida: id});
          }
        })
        .catch(err => {
          this.setState({error : err});
        })
  }

  render(){
    const { tickets, requestUpdateTicket, iniciarEdicionTicket } = this.props;
    const { idCompra, idPartida } = this.state

    const downloadFile = downloadExcel;

    // requestDeleteTicket,
    if (!tickets.fetching && !tickets.received_date) {
      return (<Message warning header='Nada para mostrar' content='No se ha hecho ninguna búsqueda' />);
    }

    if (!tickets.fetching && !tickets.all.length && !tickets.error) {
      return (<Message header='No hubo resultados' content='La búsqueda no produjo ningún resultado' />);
    }

    if (tickets.error) { 
      return (
        <Message info>
          <Message.Header>Error de captura en precio de gasolina</Message.Header>
          <Message.List>
            <Message.Item>{tickets.error.mensaje}</Message.Item>
            <Message.Item>Si requieres capturar un precio mayor acercate con la 
              persona encargada para que realice el ajuste</Message.Item>
          </Message.List>
        </Message>
      ); 
    }

    return (
      <Segment loading={tickets.fetching}>

        {
          tickets.all.length > 0 &&
          // eslint-disable-next-line react/jsx-indent
          <Button
            color='blue' style={{ margin: '0 3px 0 0' }}
            onClick={() => downloadFile(tickets, 'tikets')}
          >Reporte tickets GPS
          </Button>
        }

        <Table celled padded striped>

          {table_header(header_titles)}

          <Table.Body>
            {
              tickets.all.map(ticket => {

                const nombreColaborador = ticket.data.relations.operador ? ticket.getOperador().nombre : ticket.getAdministrativo().nombre;

                return (
                  <Table.Row key={ticket.id} positive={ticket.verificado}>
                    <Table.Cell>{ticket.id}</Table.Cell>
                    <Table.Cell>{nombreColaborador}</Table.Cell>
                    <Table.Cell>{ticket.getGasolinera().nombre}</Table.Cell>
                    <Table.Cell>{ticket.getUnidad()?.numeroEconomico}</Table.Cell>
                    <Table.Cell>{ticket.kilometraje}</Table.Cell>
                    <Table.Cell>{ticket.folio}</Table.Cell>
                    <Table.Cell>{ticket.fecha}</Table.Cell>
                    <Table.Cell>{ticket.tipoCombustible}</Table.Cell>
                    <Table.Cell>{roundToTwoDecimals(ticket.litros)}</Table.Cell>
                    <Table.Cell>{roundToTwoDecimals(ticket.precioCombustible)}</Table.Cell>
                    <Table.Cell>{roundToTwoDecimals(ticket.monto)}</Table.Cell>
                    <Table.Cell>
                    
                      {ticket.idPartida ?
                      <>
                        <p><span style={{fontWeight: 700}}>P- {ticket.idPartida}</span> 
                        </p>
                        <Popup
                            trigger={
                              <p basic color='yellow' style={{color: '#dd9208'}}>
                                { idCompra && idPartida === ticket.idPartida ? 
                                  <span>C- {idCompra}</span>
                                  :
                                  <Button color='teal' onClick={ () => this.getOrdenCompra(ticket.idPartida)}>Ver Compra</Button>
                                }
                              </p>
                            }
                            content={ticket.gasolinera.nombre}
                            size='small'
                        />
                      </>
                      :
                      <>
                      <p><span style={{fontWeight: 700}}>P- Sin relacionar</span> 
                      </p>
                      <Popup
                        trigger={
                          <p basic color='yellow' style={{color: '#dd9208'}}>
                            <span style={{fontWeight: 700}}>C- Sin orden de compra</span>
                          </p>
                        }
                        content={ticket.gasolinera.nombre}
                        size='small'
                      />
                      </>
                      }  
                    </Table.Cell>

                    <Table.Cell>
                      <Icon
                        name={ticket.viaCaptura ? 'desktop' : 'checkmark'}
                        color={ticket.viaCaptura ? 'blue' : 'green'}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Button.Group widths={3}>
                        <Button
                          basic compact color='orange' icon='edit'
                          loading={tickets.updating.id === ticket.id && tickets.updating.sending}
                          onClick={() => { iniciarEdicionTicket(ticket.id); }}
                        />

                        <Button
                          basic compact color='grey' icon={ticket.verificado ? 'check' : 'remove'}
                          loading={tickets.updating.id === ticket.id && tickets.updating.sending}
                          onClick={() => requestUpdateTicket(ticket.id, { verificado: !ticket.verificado })}
                        />
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

ListadoTickets.propTypes = {
  tickets: PropTypes.object.isRequired,
  requestUpdateTicket: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  requestDeleteTicket: PropTypes.func.isRequired,
  iniciarEdicionTicket: PropTypes.func.isRequired
};

export default ListadoTickets;