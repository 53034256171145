export const REQUEST_OPERADORES = 'REQUEST_OPERADORES';
export const REQUEST_OPERADORES_SUCCESS = 'REQUEST_OPERADORES_SUCCESS';
export const REQUEST_OPERADORES_FAILED = 'REQUEST_OPERADORES_FAILED';
export const INVALIDAR_OPERADORES = 'INVALIDAR_OPERADORES';

export const REQUEST_ADMINISTRATIVOS = 'REQUEST_ADMINISTRATIVOS';
export const REQUEST_ADMINISTRATIVOS_SUCCESS = 'REQUEST_ADMINISTRATIVOS_SUCCESS';
export const REQUEST_ADMINISTRATIVOS_FAILED = 'REQUEST_ADMINISTRATIVOS_FAILED';
export const INVALIDAR_ADMINISTRATUVOS = 'INVALIDAR_ADMINISTRATUVOS';

export const REQUEST_CLIENTES = 'REQUEST_CLIENTES';
export const REQUEST_SUCCESS_CLIENTES = 'REQUEST_SUCCESS_CLIENTES';
export const REQUEST_FAILED_CLIENTES = 'REQUEST_FAILED_CLIENTES';
export const INVALIDAR_CLIENTES = 'INVALIDAR_CLIENTES';

export const REQUEST_ESTRUCTURAS = 'REQUEST_ESTRUCTURAS';
export const REQUEST_ESTRUCTURAS_SUCCESS = 'REQUEST_ESTRUCTURAS_SUCCESS';
export const REQUEST_ESTRUCTURAS_FAILED = 'REQUEST_ESTRUCTURAS_FAILED';
export const INVALIDATE_ESTRUCTURAS = 'INVALIDATE_ESTRUCTURAS';

export const REQUEST_SUBCONTRATADOS = 'REQUEST_SUBCONTRATADOS';
export const REQUEST_SUBCONTRATADOS_SUCCESS = 'REQUEST_SUBCONTRATADOS_SUCCESS';
export const REQUEST_SUBCONTRATADOS_FAILED = 'REQUEST_SUBCONTRATADOS_FAILED';
export const INVALIDAR_SUBCONTRATADOS = 'INVALIDAR_SUBCONTRATADOS';

export const REQUEST_PROVEEDORES = 'REQUEST_PROVEEDORES';
export const REQUEST_PROVEEDORES_SUCCESS = 'REQUEST_PROVEEDORES_SUCCESS';
export const REQUEST_PROVEEDORES_FAILED = 'REQUEST_PROVEEDORES_FAILED';
export const INVALIDATE_PROVEEDORES = 'INVALIDATE_PROVEEDORES';

export const REQUEST_RUTAS = 'REQUEST_RUTAS';
export const REQUEST_RUTAS_SUCCESS = 'REQUEST_RUTAS_SUCCESS';
export const REQUEST_RUTAS_FAILED = 'REQUEST_RUTAS_FAILED';
export const INVALIDAR_RUTAS = 'INVALIDAR_RUTAS';

export const REQUEST_BITACORAS = 'REQUEST_BITACORAS';
export const REQUEST_BITACORAS_SUCCESS = 'REQUEST_BITACORAS_SUCCESS';
export const REQUEST_BITACORAS_FAILED = 'REQUEST_BITACORAS_FAILED';
export const REQUEST_DELETE_BITACORA = 'REQUEST_DELETE_BITACORA';
export const REQUEST_DELETE_BITACORA_SUCCESS = 'REQUEST_DELETE_BITACORA_SUCCESS';
export const REQUEST_DELETE_BITACORA_FAILED = 'REQUEST_DELETE_BITACORA_FAILED';
export const REQUEST_UPDATE_BITACORA = 'REQUEST_UPDATE_BITACORA';
export const REQUEST_UPDATE_BITACORA_SUCCESS = 'REQUEST_UPDATE_BITACORA_SUCCESS';
export const REQUEST_UPDATE_BITACORA_FAILED = 'REQUEST_UPDATE_BITACORA_FAILED';
export const INICIAR_EDICION_BITACORA = 'INICIAR_EDICION_BITACORA';
export const FINALIZAR_EDICION_BITACORA = 'FINALIZAR_EDICION_BITACORA';

export const REQUEST_BUSCAR_TICKETS = 'REQUEST_BUSCAR_TICKETS';
export const REQUEST_BUSCAR_TICKETS_SUCCESS = 'REQUEST_BUSCAR_TICKETS_SUCCESS';
export const REQUEST_BUSCAR_TICKETS_FAILED = 'REQUEST_BUSCAR_TICKETS_FAILED';
export const REQUEST_CREATE_TICKET = 'REQUEST_CREATE_TICKET';
export const REQUEST_CREATE_TICKET_SUCCESS = 'REQUEST_CREATE_TICKET_SUCCESS';
export const REQUEST_CREATE_TICKET_FAILED = 'REQUEST_CREATE_TICKET_FAILED';
export const REQUEST_UPDATE_TICKET = 'REQUEST_UPDATE_TICKET';
export const REQUEST_UPDATE_TICKET_SUCCESS = 'REQUEST_UPDATE_TICKET_SUCCESS';
export const REQUEST_UPDATE_TICKET_FAILED = 'REQUEST_UPDATE_TICKET_FAILED';
export const REQUEST_DELETE_TICKET = 'REQUEST_DELETE_TICKET';
export const REQUEST_DELETE_TICKET_SUCCESS = 'REQUEST_DELETE_TICKET_SUCCESS';
export const REQUEST_DELETE_TICEKT_FAILED = 'REQUEST_DELETE_TICEKT_FAILED';
export const INICIAR_EDICION_TICKET = 'INICIAR_EDICION_TICKET';
export const FINALIZAR_EDICION_TICKET = 'FINALIZAR_EDICION_TICKET';

export const REQUEST_INVENTARIO_CELULARES = 'REQUEST_INVENTARIO_CELULARES';
export const REQUEST_INVENTARIO_CELULARES_SUCCESS = 'REQUEST_INVENTARIO_CELULARES_SUCCESS';
export const REQUEST_INVENTARIO_CELULARES_FAILED = 'REQUEST_INVENTARIO_CELULARES_FAILED';
export const REQUEST_CREATE_EQUIPO_CELULAR = 'REQUEST_CREATE_EQUIPO_CELULAR';
export const REQUEST_CREATE_EQUIPO_CELULAR_SUCCESS = 'REQUEST_CREATE_EQUIPO_CELULAR_SUCCESS';
export const REQUEST_CREATE_EQUIPO_CELULAR_FAILED = 'REQUEST_CREATE_EQUIPO_CELULAR_FAILED';
export const START_CREATE_EQUIPO_CELULAR = 'START_CREATE_EQUIPO_CELULAR';
export const END_CREATE_EQUIPO_CELULAR = 'END_CREATE_EQUIPO_CELULAR';
export const START_UPDATE_EQUIPO_CELULAR = 'START_UPDATE_EQUIPO_CELULAR';
export const END_UPDATE_EQUIPO_CELULAR = 'END_UPDATE_EQUIPO_CELULAR';
export const REQUEST_UPDATE_EQUIPO_CELULAR = 'REQUEST_UPDATE_EQUIPO_CELULAR';
export const REQUEST_UPDATE_EQUIPO_CELULAR_SUCCESS = 'REQUEST_UPDATE_EQUIPO_CELULAR_SUCCESS';
export const REQUEST_UPDATE_EQUIPO_CELULAR_FAILED = 'REQUEST_UPDATE_EQUIPO_CELULAR_FAILED';

export const REQUEST_COLABORADORES = 'REQUEST_COLABORADORES';
export const REQUEST_COLABORADORES_SUCCESS = 'REQUEST_COLABORADORES_SUCCESS';
export const REQUEST_COLABORADORES_FAILED = 'REQUEST_COLABORADORES_FAILED';

export const REQUEST_CLIENTES_RUTAS = 'REQUEST_CLIENTES_RUTAS';
export const REQUEST_CLIENTES_RUTAS_SUCCESS = 'REQUEST_CLIENTES_RUTAS_SUCCESS';
export const REQUEST_CLIENTES_RUTAS_FAILED = 'REQUEST_CLIENTES_RUTAS_FAILED';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS';
export const REQUEST_LOGIN_FAILED = 'REQUEST_LOGIN_FAILED';
export const INVALIDAR_LOGIN = 'INVALIDAR_LOGIN';
