import React, { Component } from 'react'
import { Form, Container, Message, Grid, Button } from 'semantic-ui-react'
import axios from 'axios';
import { 
    ENDPOINT_USUARIOS_CELULARES,
} from '../../constants/endpoints';

import { condicionEquipo, extraerOpcionesLineasCelulares, extraerOpcionesEquiposCelulares, extraerOpcionesUsuariosEquiposCelulares } from '../../helpers/index';

class AdministrarAsignarEquipos extends Component {
    constructor(props){
        super(props)

        this.initialValues = {
            success: null
        }   

        this.state = {
            ...this.initialValues
        }
    }

    onChange = (e, data) => {
        if (data) {
            this.setState({ [data.name]: data.value })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    onSubmit = () => {
        const params = [];

        if(this.state.usuarioSeleccionado){
            params.push(`usuarioId=${this.state.usuarioSeleccionado}`)
        }

        if(this.state.lineasSeleccionado){
            params.push(`lineaId=${this.state.lineasSeleccionado}`)
        }

        if(this.state.equipoSeleccionado){
            params.push(`celularId=${this.state.equipoSeleccionado}`)
        }

        if(this.state.accionSeleccionada){
            params.push(`accion=${this.state.accionSeleccionada}`)
        }

        const url = `${ENDPOINT_USUARIOS_CELULARES}` + 'asignar' + (params.length ? `?${params.join('&')}` : '');

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        success: response.data.success,
                        message: response.data.message,
                        details: response.data.details
                    })

                    setTimeout(() => this.setState({...this.initialValues,
                        usuarioSeleccionado: '',
                        lineasSeleccionado: '',
                        equipoSeleccionado: '',
                        accionSeleccionada: '',
                     }), 3000);
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    selectedUsuario = (id) => {
        const url = `${ENDPOINT_USUARIOS_CELULARES}` + 'elemento' + `?id=${id}`;

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        equipo: response.data.data
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    onReset = (e) => {
        e.preventDefault()
        this.setState({
            ...this.initialValues,
            emptyData: false,
            usuarioSeleccionado: '',
            lineasSeleccionado: '',
            equipoSeleccionado: '',
            accionSeleccionada: '',
        })
    }

    render () {
        const { asignar, respuestas } = this.props
        const { accionSeleccionada, equipoSeleccionado, lineasSeleccionado,  usuarioSeleccionado, success, error, message, details } = this.state

        return(
            <>
                <Container>
                    <Grid columns={1} relaxed='very' stackable>
                        <Grid.Column>
                        {
                        asignar && respuestas ?
                            <>
                            <h3>Asignar Equipo</h3>
                            <Form form='formAsignarEquipo' onSubmit={this.onSubmit}>
                                {
                                    respuestas.equipos ?
                                    <Form.Select
                                        name='equipoSeleccionado' label='Selecciona el IMEI del equipo' fluid search selection
                                        options={respuestas.equipos.map(extraerOpcionesEquiposCelulares)}
                                        value={equipoSeleccionado}
                                        placeholder='IMEI'
                                        onChange={this.onChange}
                                    />
                                    : <></>
                                }

                                {
                                    respuestas.lineas ? 
                                        <Form.Select
                                            name='lineasSeleccionado' label='Selecciona la línea' fluid search selection
                                            options={respuestas.lineas?.map(extraerOpcionesLineasCelulares)}
                                            value={lineasSeleccionado}
                                            placeholder='Línea'
                                            onChange={this.onChange}
                                        />
                                    : <></>
                                }
                                
                                {
                                    respuestas.usuarios ? 
                                    <Form.Select
                                        name='usuarioSeleccionado' label='Selecciona al usuario' fluid search selection
                                        options={respuestas.usuarios?.map(extraerOpcionesUsuariosEquiposCelulares)}
                                        value={usuarioSeleccionado}
                                        placeholder='Usuario'
                                        onChange={this.onChange}
                                    />
                                    : <></>
                                }
                                
                                <Form.Select
                                    name='accionSeleccionada' label='Selecciona la acción a realizar' fluid search selection
                                    options={condicionEquipo}
                                    value={accionSeleccionada}
                                    placeholder='Acción'
                                    onChange={this.onChange}
                                />
                                <Form.Group inline>
                                    <Form.Field>
                                        <Form.Button id='guardar' color='orange'>Guardar</Form.Button>
                                    </Form.Field>
                                    <Button.Group>
                                        <Button onClick={this.onReset} fluid>Limpiar</Button>
                                    </Button.Group>
                                </Form.Group>
                            </Form></>:<></>
                        }
                                                
                        {
                            success === true ? (
                                <Message positive>
                                    <Message.Header>Proceso terminado</Message.Header>
                                    <Message.Content>La acción de {accionSeleccionada} el equipo y la línea se realizó correctamente</Message.Content>
                                </Message>
                            ):(
                                <></>
                            )
                        }

                        {
                            success === false ? (
                                <Message error header={message} list={details}/>
                            ):(
                            <></> 
                            )
                        }
                        </Grid.Column>
                        <Grid.Row>
                            
                        </Grid.Row>
                    </Grid>
                    {
                        error &&
                        <Message negative>
                            <Message.Header>Error ({error})</Message.Header>
                        {error}
                        </Message>
                    }
                </Container>
            </>
        )
    }
}

export default AdministrarAsignarEquipos