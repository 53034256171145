import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';

import {
    REQUEST_CLIENTES_RUTAS
} from '../constants/action_types';

import {
    ENDPOINT_CLIENTES_RUTAS
} from '../constants/endpoints';

import {
    onRequestClientesRutasSuccess,
    onRequestClientesRutasFailed
} from '../creators/cliente_rutas';

function * fetchClientesRutas ({ payload: { params } }) {
    try {
        const url = `${ENDPOINT_CLIENTES_RUTAS}` + (params.length ? `?${params.join('&')}` : '');
        const response = yield call(axios.post, url);

        yield put(onRequestClientesRutasSuccess(response));
    } catch (e) {
        yield put(onRequestClientesRutasFailed(e.response));
    }
}

export function * watchRequestClientesRutas () {
    yield takeEvery(REQUEST_CLIENTES_RUTAS, fetchClientesRutas);
}
