import React, { Component } from 'react'
import { Form, Container, Message, Button, Divider } from 'semantic-ui-react'
import axios from 'axios';
import { downloadExcelCelulares } from '../../helpers/index';
import ListadoResultados from './ListadoResultados'

import { 
    ENDPOINT_USUARIOS_CELULARES,
} from '../../constants/endpoints';

import { 
    extraerOpcionesModelosEquiposCelulares, 
    extraerOpcionesEstatusEquiposCelulares,
    extraerOpcionesMarcasEquiposCelulares,
    extraerOpcionesLineasCelulares,
    extraerOpcionesUsuariosEquiposCelulares,
    equipoAtivo
} from '../../helpers/index';

class AdministrarBusquedas extends Component {
    constructor(props){
        super(props)

        this.initialValues = {
            success: null,
        }   

        this.state = {
            ...this.initialValues
        }
    }

    onChange = (e, data) => {
        if (data) {
            //this.setState({data: { ...this.state.data, [data.name]: data.value }})
            this.setState({[data.name]: data.value })
        } else {
            this.setState({[e.target.name]: e.target.value })
        }
    }

    onSubmit = () => {
        const params = [];

        if(this.state.usuarioSeleccionado){
            params.push(`usuario=${this.state.usuarioSeleccionado}`)
        }

        if(this.state.lineasSeleccionado){
            params.push(`linea=${this.state.lineasSeleccionado}`)
        }

        if(this.state.marcaSeleccionada){
            params.push(`marca=${this.state.marcaSeleccionada}`)
        }

        if(this.state.modeloSeleccionado){
            params.push(`modelo=${this.state.modeloSeleccionado}`)
        }

        if(this.state.condicionesSeleccionado){
            params.push(`status=${this.state.condicionesSeleccionado}`)
        }

        if(this.state.estatusSeleccionado){
            params.push(`activo=${this.state.estatusSeleccionado}`)
        }

        const url = `${ENDPOINT_USUARIOS_CELULARES}` + 'lista'+ (params.length ? `?${params.join('&')}` : '');

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    if(response.data){
                        if(response.data.data.length != 0){
                            this.setState({
                                success: response.data.success,
                                message: response.data.data.message,
                                details: response.data.data.details,
                                resultadoBusqueda: response.data.data,
                                emptyData: false
                            })
                        }

                        if(response.data.data.length == 0){
                            this.setState({
                                success: response.data.success,
                                message: response.data.data.message,
                                details: response.data.data.details,
                                emptyData: true,
                            })
                        }

                        setTimeout(() => this.setState({...this.initialValues }), 3000);
                    }
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    onReset = (e) => {
        e.preventDefault()
        this.setState({
            ...this.initialValues,
            emptyData: false,
            resultadoBusqueda: null,
            usuarioSeleccionado: '',
            lineasSeleccionado: '',
            marcaSeleccionada: '',
            modeloSeleccionado: '',
            condicionesSeleccionado: '',

        })
    }

    render () {
        const { buscar, respuestas } = this.props
        const { resultadoBusqueda, emptyData, success, error, 
                usuarioSeleccionado, lineasSeleccionado, marcaSeleccionada,
                modeloSeleccionado, condicionesSeleccionado, estatusSeleccionado
              } = this.state

        return(
            <>
            <h3>Buscar registros</h3>
                <Container>
                    {
                    buscar ?
                    <>
                    <Form form='formBuscarRegistros' onSubmit={this.onSubmit}>
                        <Form.Group widths='equal'>
                            {
                                respuestas.usuarios ?
                                <Form.Select
                                    name='usuarioSeleccionado' label='Selecciona al usuario' fluid search selection
                                    options={respuestas.usuarios.map(extraerOpcionesUsuariosEquiposCelulares)}
                                    value={usuarioSeleccionado}
                                    placeholder='Usuario'
                                    onChange={this.onChange}
                                />:<></> // EDITAR AL NUEVO ENDPOINT
                            }
                            {
                                respuestas.lineas ?
                                <Form.Select
                                    name='lineasSeleccionado' label='Selecciona la línea' fluid search selection
                                    options={respuestas.lineas.map(extraerOpcionesLineasCelulares)}
                                    value={lineasSeleccionado}
                                    placeholder='Línea'
                                    onChange={this.onChange}
                                />:<></>
                            }
                            {
                                respuestas.marcas ?
                                <Form.Select
                                    name='marcaSeleccionada' label='Selecciona la marca' fluid search selection
                                    options={respuestas.marcas.map(extraerOpcionesMarcasEquiposCelulares)}
                                    value={marcaSeleccionada}
                                    placeholder='Marca'
                                    onChange={this.onChange}
                                />:<></>
                            }
                            </Form.Group>
                            <Form.Group widths='equal'>
                            {
                                respuestas.modelos ?
                                <Form.Select
                                    name='modeloSeleccionado' label='Selecciona el modelo' fluid search selection
                                    options={respuestas.modelos.map(extraerOpcionesModelosEquiposCelulares)}
                                    value={modeloSeleccionado}
                                    placeholder='Modelo'
                                    onChange={this.onChange}
                                />:<></>
                            }
                            {
                                respuestas.condiciones ?
                                <Form.Select
                                    name='condicionesSeleccionado' label='Selecciona las condiciones del equipo' fluid search selection
                                    options={respuestas.condiciones.map(extraerOpcionesEstatusEquiposCelulares)}
                                    value={condicionesSeleccionado}
                                    placeholder='Condiciones del equipo'
                                    onChange={this.onChange}
                                />:<></>
                            }
                            {
                                respuestas.estatus &&
                                <Form.Select
                                    name='estatusSeleccionado' label='Selecciona el estatus del equipo' fluid search selection
                                    options={equipoAtivo}
                                    value={estatusSeleccionado}
                                    placeholder='Estatus del equipo'
                                    onChange={this.onChange}
                                />
                            }

                        </Form.Group>
                        <Form.Group inline>
                            <Form.Field>
                                <Form.Button color='orange' fluid>Buscar</Form.Button>
                            </Form.Field>
                            <Button.Group>
                                <Button onClick={this.onReset} fluid>Limpiar</Button>
                            </Button.Group>

                            {
                                resultadoBusqueda &&
                                <Button.Group>
                                    <Button
                                        color='teal' style={{ margin: '0 0 0 20px' }}
                                        onClick={() => downloadExcelCelulares(resultadoBusqueda, 'celulares')}
                                        >Descargar resultados de búsqueda
                                    </Button>
                                </Button.Group>
                            }
                        </Form.Group>
                    </Form>
                    
                    </>
                    :<></>
                    }
                    { emptyData && !resultadoBusqueda ?
                        <Message
                            info
                            header='Sin resultados'
                            content="Intente ingresando otros datos"
                        />: <></>
                    }
                    <Divider hidden />
                    {
                        resultadoBusqueda && !emptyData &&
                        <ListadoResultados lista={resultadoBusqueda} respuestas={respuestas}/>
                    }
                    {
                        error == false &&
                        <Message error>
                            <Message.Header>{error}</Message.Header>
                            {error}
                        </Message>
                    }
                </Container>
            </>
        )
    }
}

export default AdministrarBusquedas