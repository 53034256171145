import React, { Component } from 'react'
import { Form, Container, Message, Grid, Button, Icon, Table } from 'semantic-ui-react'
import axios from 'axios';
import { ENDPOINT_MARCAS_CELULARES, ENDPOINT_MODELOS_CELULARES } from '../../constants/endpoints';

import { extraerOpcionesMarcasEquiposCelulares, extraerOpcionesModelosEquiposCelulares } from '../../helpers/index';

class AdministrarModelos extends Component {
    constructor(props){
        super(props)

        this.initialValues = {
            registrar: false,
            editar: false,
            buscar: false,
            modelo: '', 
            marcaSeleccionada: '',
            modeloSeleccionado: '',
            success: null
        }   

        this.state = {
            ...this.initialValues
        }
    }

    getMarcas () {
        const url = `${ENDPOINT_MARCAS_CELULARES}` + 'lista';

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        marcas: response.data.data,
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    getModelos () {
        const url = `${ENDPOINT_MODELOS_CELULARES}` + 'lista';

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        modelos: response.data.data,
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    handleClickButton = (e, data) => {
        switch (data.id) {
            case 'registrar':
                this.getMarcas()
                this.setState({
                    ...this.initialValues,
                    registrar: true,
                })
                break;
            case 'buscar':
                this.getModelos()
                this.setState({
                    ...this.initialValues,
                    buscar: true,
                })
                break;
            case 'editarInputs':
                this.getMarcas()
                this.setState({
                    openInputs: true,
                })
                break;
            case 'editar':
                this.setState({
                    editar: true,
                })
                break;
            default:
                this.setState({
                    ...this.initialValues,
                })
                break;
        }
    }

    onChange = (e, data) => {
        if (data) {
            this.setState({ [data.name]: data.value })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }

        if(data.name === 'modeloSeleccionado'){
            this.selectedModel(data.value)
            this.setState({
                editar: true,
            })
        }
    }

    onSubmit = () => {

        const params = [];

        if(this.state.marcaSeleccionada){
            params.push(`marca=${this.state.marcaSeleccionada}`)
        }

        if(this.state.modelo){
            params.push(`modelo=${this.state.modelo}`)
        }

        const url = `${ENDPOINT_MODELOS_CELULARES}` + 'add' + (params.length ? `?${params.join('&')}` : '');

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        success: response.data.success,
                        message: response.data.message,
                        details: response.data.details
                    })

                    setTimeout(() => this.setState({...this.initialValues }), 3000);
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    selectedModel = (id) => {
        
        const url = `${ENDPOINT_MODELOS_CELULARES}` + 'elemento' + `?id=${id}`;

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        modelo: response.data.data
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    onEdit = () => {
        const params = [];

        if(this.state.marcaSeleccionada){
            params.push(`marca=${this.state.marcaSeleccionada}`)
        }

        if(this.state.modeloSeleccionado){
            params.push(`id=${this.state.modeloSeleccionado}`)
        }

        if(this.state.nuevoNombre){
            params.push(`modelo=${this.state.nuevoNombre}`)
        }

        const url = `${ENDPOINT_MODELOS_CELULARES}` + 'modificar' + (params.length ? `?${params.join('&')}` : '');

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        success: response.data.success,
                        message: response.data.message,
                        details: response.data.details
                    })

                    setTimeout(() => this.setState({...this.initialValues }), 3000);
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    render () {
        const { registros } = this.props
        const { modelo, nuevoNombre, marcas, modelos, registrar, editar, buscar, openInputs, success, error, message, details } = this.state

        return(
            <>
                <Container>
                    <Grid columns={2} relaxed='very' stackable>
                        <Grid.Column>
                            {
                                registros.modelo ?
                                <>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <Button id='registrar' color='green' onClick={this.handleClickButton} size='medium' style={{marginBottom: '10px'}}>Registrar modelo</Button>
                                    <Button id='buscar' color='green' onClick={this.handleClickButton} size='medium'>Editar modelo</Button>
                                </div>
                                </>:<></>
                            }
                        </Grid.Column>
                        <Grid.Column>
                            {
                               marcas && registrar ?
                                <>
                                <h3>Registrar Modelos</h3>
                                <Form form='formRegistrarModelo' onSubmit={this.onSubmit}>
                                    <Form.Input 
                                        label='Ingresa el nombre del modelo' 
                                        name='modelo'
                                        value={this.state.modelo}
                                        onChange={this.onChange}
                                        placeholder='Modelo'
                                        required
                                    />
                                    <Form.Select
                                        name='marcaSeleccionada' label='Selecciona la marca' fluid search selection
                                        options={marcas.map(extraerOpcionesMarcasEquiposCelulares)}
                                        value={marcas.ID}
                                        placeholder='Marca'
                                        onChange={this.onChange}
                                    />
                                    <Form.Field>
                                        <Form.Button id='guardar' color='orange'>Registrar</Form.Button>
                                    </Form.Field>
                                </Form></>:<></>
                            }
                            {
                                success === true && registrar ? (
                                    <Message positive>
                                        <Message.Header>Modelo {modelo}</Message.Header>
                                        <Message.Content>Registrado correctamente</Message.Content>
                                    </Message>
                                ):(
                                    <></>
                                )
                            }

                            {
                                success === false  && registrar ? (
                                    <Message error header={message} list={details}/>
                                ):(
                                <></> 
                                )
                            }

                            { modelos && buscar ? 
                                <>
                                 <h3>Editar Modelo</h3>
                                 <Form form='formBuscarModelo'>
                                    <Form.Select
                                        name='modeloSeleccionado' label='Selecciona el modelo a editar' fluid search selection
                                        options={modelos?.map(extraerOpcionesModelosEquiposCelulares)}
                                        value={modelos.ID}
                                        placeholder='Modelo'
                                        onChange={this.onChange}
                                    />
                                 </Form>
                                </>:<></>
                            }
                        </Grid.Column>
                        <Grid.Row>
                            
                        </Grid.Row>
                    </Grid>
                    {
                        modelo && editar &&
                        <>
                        <div style={{margin: '3rem 0 0 0', display: 'flex', flexDirection: 'column'}}>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell textAlign='center'>Id</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Modelo</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Marca</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Acciones</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell singleLine textAlign='center'>{modelo[0].ID}</Table.Cell>
                                        <Table.Cell singleLine textAlign='center'>{modelo[0].MODELO}</Table.Cell>
                                        <Table.Cell singleLine textAlign='center'>{modelo[0].MARCA}</Table.Cell>
                                        <Table.Cell singleLine textAlign='center'>
                                            <Icon name='edit' color="orange" size='large' id='editarInputs' onClick={this.handleClickButton}/>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                        {
                            openInputs && marcas ?
                            <div style={{margin: '3rem 0 0 0'}}>
                                <Message info header='Ambos campos son obligatorios'
                                content="Si alguno de los dos campos no cambia, registralo con el dato anterior."
                                />
                                <Form form='formEditarModelo' onSubmit={this.onEdit}>
                                    <Form.Select
                                        name='marcaSeleccionada' label='Selecciona la marca' fluid search selection
                                        options={marcas.map(extraerOpcionesMarcasEquiposCelulares)}
                                        value={marcas.ID}
                                        placeholder='Marca'
                                        onChange={this.onChange}
                                    />
                                    <Form.Input 
                                        label='Ingresa el nuevo nombre del modelo' 
                                        name='nuevoNombre'
                                        value={nuevoNombre}
                                        placeholder='Nuevo nombre del modelo'
                                        onChange={this.onChange}
                                        required
                                    />
                                    <Form.Field>
                                    <Form.Button id='editar' color='orange'
                                        onClick={this.handleClickButton}>Editar</Form.Button>
                                    </Form.Field>
                                </Form>
                            </div>:<></>
                        }
                                                       
                        {
                            success === true && editar ? (
                                <Message positive>
                                    <Message.Header>Nuevo modelo {nuevoNombre}</Message.Header>
                                    <Message.Content>Editado correctamente</Message.Content>
                                </Message>
                            ):(
                                <></>
                            )
                        }

                        {
                            success === false && editar ? (
                                <Message error header={message} list={details}/>
                            ):(
                            <></> 
                            )
                        }

                        </>
                    }
                    {
                        error &&
                        <Message negative>
                            <Message.Header>Error ({error})</Message.Header>
                        {error}
                        </Message>
                    }
                </Container>
            </>
        )
    }
}

export default AdministrarModelos