import React, { Component } from 'react'
import { Form, Segment, Container, Dimmer, Loader } from 'semantic-ui-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import axios from 'axios'
import { ENDPOINT_CLIENTES_RUTAS } from '../../constants/endpoints'
import ListadoClientesRutas from './ListadoClientesRutas'
import _ from 'lodash'

// desde: moment().day(3).format('YYYY-MM-DD'),
// hasta: moment().day(3).format('YYYY-MM-DD'),

class FormaBuscarClientesRutas extends Component {
  constructor(props) {
    super(props)

    this.initialValues = {
      desde: moment().day(),
      hasta: moment().day(),
      id: '',
      clientes: this.props,
      isLoading: false
    }

    this.state = {
      ...this.initialValues
    }
  }

  onChangeValue = (event, data) => {
    if (data) {
     
      this.setState({ [data.name]: data.value })
    
    } else {
      this.setState({ [event.target.name]: event.target.value })
    }
  }

  onSubmit = () => {
    this.setState({
      isLoading: true
    })

    const params = []

    if (this.state.id) {
      params.push(`idCliente=${this.state.id}`)
    }

    if (this.state.desde) {
      params.push(`fechaDesde=${this.state.desde}`)
    }

    if (this.state.hasta) {
      params.push(`fechaHasta=${this.state.hasta}`)
    }

    const url = `${ENDPOINT_CLIENTES_RUTAS}` + (params.length === 3 ? `?${params.join('&')}` : '')

    axios.post(url)
      .then(response => {
        const respuesta = response.data

        this.setState({
          respuesta,
          isLoading: false
        })
      })
      .catch(err => {
        console.log('Error: ', err)
      })
  }

  onReset = () => {
    this.setState({
      ...this.initialValues
    })
  }

  render() {
    const { respuesta, desde, hasta, clientes, isLoading } = this.state

    const clienteValue = clientes.clientes.clientes.map(cliente => {
      return cliente
    })

    const opcionesCliente = _.uniq(clienteValue).map((data) => {
      return {
        key: data.data.id,
        text: data.data.attributes.nombreEmpresa,
        value: data.data.id
      }
    })

    return (
      <Container fluid style={{ padding: '10px' }}>
        <Segment color='orange'>
          <Form form='formaBuscarEquiposCelulares' onSubmit={this.onSubmit}>
            <Form.Group widths='equal'>
              <Form.Input
                fluid required label='Desde' name='desde' type='date' value={desde}
                onChange={this.onChangeValue}
              />
              <Form.Input
                fluid required label='Hasta' name='hasta' type='date' value={hasta}
                onChange={this.onChangeValue}
              />
              <Form.Select
                fluid
                search
                selection
                placeholder='Nombre del cliente'
                label='Cliente'
                name='id'
                options={opcionesCliente}
                value={this.state.id}
                required
                onChange={this.onChangeValue}
              />
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <Form.Button color='orange'>Buscar</Form.Button>
              </Form.Field>
              <Form.Field>
                <Form.Button onClick={this.onReset}>Limpiar</Form.Button>
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>

        {
          isLoading === true &&
          <Dimmer active inverted page><Loader size='large'>Cargando</Loader></Dimmer>
        }

        {
          respuesta && isLoading === false &&
          <ListadoClientesRutas
            data={respuesta}
          />
        }
      </Container>

    )
  }
}

FormaBuscarClientesRutas.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  clientes: PropTypes.object.isRequired
}

export default FormaBuscarClientesRutas
