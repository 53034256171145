import BaseModel from './BaseModel';

class Usuario extends BaseModel {
    // eslint-disable-next-line space-before-function-paren
    constructor(data) {
        super(data);

        this.nombre = this.getAttribute('nombre');
        this.email = this.getAttribute('email');
        this.acceso = this.getAttribute('acceso');
        this.activo = this.getAttribute('activo');
        this.iniciales = this.getAttribute('iniciales');
        this.supervisor = this.getAttribute('supervisor');

        //this.id = 4250;
    }

    // eslint-disable-next-line space-before-function-paren
    puedeGenerarReporte() {
        return this.id === 3746 || this.id === 4112 || this.id === 94;
    }
}

export default Usuario;
