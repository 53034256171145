import React, { Component } from "react";
import { Table } from "semantic-ui-react";

class ResultadosEmpresarial extends Component {
    constructor(props){
        super(props)

        this.state = ({

        })
    }

  render() {
    const listaResultados = this.props.listaPorOperador;
    let totalPagoGeneral = null
    let totalPagoUnitario = null
    let totalViajes = null

    const sumaPagado = listaResultados.EMPRESARIAL.BITACORAS.map((item) => {
      return item.TOTAL_PAGO_CHOFER
    })

    const sumaUnitario = listaResultados.EMPRESARIAL.BITACORAS.map((item) => {
      return item.PAGO_CHOFER_UNITARIO
    })

    const sumaViajes = listaResultados.EMPRESARIAL.BITACORAS.map((item) => {
      return item.NO_VIAJES
    })

    totalPagoGeneral = new Intl.NumberFormat('es-MX').format(sumaPagado.reduce((a, b) => a + b, 0))
    totalPagoUnitario = new Intl.NumberFormat('es-MX').format(sumaUnitario.reduce((a, b) => a + b, 0))
    totalViajes = sumaViajes.reduce((a, b) => a + b, 0)

    return (
      <div>
        { listaResultados.EMPRESARIAL.BITACORAS.length ?
          <Table celled structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='5' textAlign='center'>
                <h3>
                  Servicio Empresarial
                </h3>
                <h4>
                  Suma pago operador: <span style={{color: '#f2711c'}}>$ {totalPagoGeneral}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                  Suma pago unitario: <span style={{color: '#f2711c'}}>$ {totalPagoUnitario}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                  Total de viajes: <span style={{color: '#f2711c'}}>{totalViajes}</span>
                </h4>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell textAlign='center'>Ruta</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Tipo</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Viajes</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Pago unitario</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Total pago</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          { listaResultados.EMPRESARIAL.BITACORAS.map((item, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell textAlign='left'>{item.NOMBRE_RUTA}</Table.Cell>
                <Table.Cell textAlign='center'>{item.TIPO}</Table.Cell>
                <Table.Cell textAlign='center'>{item.NO_VIAJES}</Table.Cell>
                <Table.Cell textAlign='center'>$ {item.PAGO_CHOFER_UNITARIO}</Table.Cell>
                <Table.Cell textAlign='center'>$ {item.TOTAL_PAGO_CHOFER}</Table.Cell>
              </Table.Row>
            )
          })}
          </Table.Body>
          </Table>
          :
          <></>
        }
      </div>
    );
  }
}

export default ResultadosEmpresarial;