import React, { Component } from 'react';
import { Container, Grid, Button, Segment } from 'semantic-ui-react';
import axios from 'axios';
import AdministrarMarcas from './AdministrarMarcas';
import AdministrarModelos from './AdministrarModelos';
import AdministrarEquipos from './AdministrarEquipos';
import AdministrarLineas from './AdministrarLineas';
import AdministrarBusquedas from './AdministrarBusquedas';
import AdministrarAsignarEquipos from './AdministrarAsignar';
import Historial from './Historial';

import { 
    ENDPOINT_MARCAS_CELULARES,
    ENDPOINT_MODELOS_CELULARES,
    ENDPOINT_EQUIPOS_CELULARES,
    ENDPOINT_ESTATUS_CELULARES,
    ENDPOINT_STATUS_CELULARES,
    ENDPOINT_LINEAS_CELULARES,
    ENDPOINT_USUARIOS_CELULARES,
    ENDPOINT_HISTORIAL_CELULARES,
    ENDPOINT_COLABORADORES_CELULARES
} from '../../constants/endpoints';

import { downloadExcelHistorialCelulares } from '../../helpers/index'

class AdministrarEquiposCelulares extends Component {

    constructor(props) {
        super(props)
    
        this.initialValues = {
            registros: {
                marca: false,
                modelo: false,
                equipo: false,
                linea: false,
                registrar: false,
                editar: false
            },
            respuestas: {
                marcas: null,
                modelos: null,
                equipos: null,
                condiciones: null,
                lineas: null,
                usuarios: null,
                estatus: null
            },
            buscar: false,
            asignar: false,
            registrar: false,
            historial: false,
        }
    
        this.state = {
          ...this.initialValues
        }
    }

    getMarcas () {
        const url = `${ENDPOINT_MARCAS_CELULARES}` + 'lista';
        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        respuestas: {
                            ...this.state.respuestas,
                            marcas: response.data.data
                        }
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    getModelos () {
        const url = `${ENDPOINT_MODELOS_CELULARES}` + 'lista';

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        respuestas: {
                            ...this.state.respuestas,
                            modelos: response.data.data
                        }
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    getEquipos () {
        const url = `${ENDPOINT_EQUIPOS_CELULARES}` + 'lista';

        axios.post(url)
            .then(response => {
                if(response.status == 200) {
                    this.setState({
                        respuestas: {
                            ...this.state.respuestas,
                            equipos: response.data.data
                        }
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    getCondiciones () {
        const url = `${ENDPOINT_ESTATUS_CELULARES}` + 'lista';

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        respuestas: {
                            ...this.state.respuestas,
                            condiciones: response.data.data
                        }
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    getLineas () {
        const url = `${ENDPOINT_LINEAS_CELULARES}` + 'lista';
        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        respuestas: {
                            ...this.state.respuestas,
                            lineas: response.data.data
                        }
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    getUsuarios () {
        //const url = `${ENDPOINT_USUARIOS_CELULARES}` + 'lista';    
        const url = `${ENDPOINT_COLABORADORES_CELULARES}` + 'lista'; 
        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        respuestas: {
                            ...this.state.respuestas,
                            usuarios: response.data.data
                        }
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    getEstatus () {
        const url = `${ENDPOINT_STATUS_CELULARES}` + 'lista';
        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        respuestas: {
                            ...this.state.respuestas,
                            estatus: response.data.data
                        }
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    getHistorial () {
        const url = `${ENDPOINT_HISTORIAL_CELULARES}` + 'lista';

        axios.post(url)
            .then(response => {
                if(response.status == 200) {
                    this.setState({
                        historico: response.data.data,
                        cantidadRegistros:  response.data.data.length,
                        success: true
                    })

                    downloadExcelHistorialCelulares(this.state.historico, 'historial')
                    setTimeout(() => this.setState({...this.initialValues }), 3000);
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                    success: false
                })
                setTimeout(() => this.setState({...this.initialValues }), 3000);
                console.log('Error: ', err);
            })
    }

    handleClick = (e, data) => {
        switch (data.id) {
            case 'marca':
                this.setState({
                    ...this.initialValues,
                    registrar: true,
                    registros: {marca: true, registrar: true, editar: true},
                    show: true
                })
                break;
            case 'modelo':
                this.setState({
                    ...this.initialValues,
                    registrar: true,
                    registros: {modelo: true, registrar: true, editar: true},
                    show: true
                })
                break;
            case 'equipo':
                this.setState({
                    ...this.initialValues,
                    registrar: true,
                    registros: {equipo: true, registrar: true, editar: true},
                    show: true
                })
                break;
            case 'linea':
                this.setState({
                    ...this.initialValues,
                    registrar: true,
                    registros: {linea: true, registrar: true, editar: true},
                    show: true
                })
                break;
            case 'buscar':
                this.getMarcas()
                this.getModelos()
                this.getEquipos()
                this.getCondiciones()
                this.getLineas()
                this.getUsuarios()
                this.getEstatus()
                this.setState({
                    ...this.initialValues,
                    buscar: true,
                    show: true
                })
                break;
            case 'asignar':
                this.getEquipos()
                this.getLineas()
                this.getUsuarios()
                this.setState({
                    ...this.initialValues,
                    asignar: true,
                    show: true
                })
                break;
            case 'historial':
                this.getHistorial()
                this.setState({
                    ...this.initialValues,
                    historial: true,
                    show: true
                })
                break;
            default:
                this.setState({
                    ...this.initialValues,
                })
                break;
        }
    }
    
    render () {
        const { show, registros, buscar, asignar, registrar, historial, cantidadRegistros, respuestas } = this.state

        return (
            <Container fluid>
                <h2 style={{padding: '0 .5rem'}}>Control de equipos celulares y líneas telefónicas</h2>
                <Segment color='orange'>
                    <Grid stackable columns={2} divided style={{padding: '0 1.5rem'}}>
                        <Grid.Row>
                            <Grid.Column style={{width: '35%'}}>
                                <Grid.Row>
                                    <Grid.Row stretched style={{margin: "0.25em"}}>
                                        <h3>Registrar Equipos y líneas</h3>
                                        <Button id='marca' color='orange' style={{margin: "0 0.25em 0.25em 0"}}
                                        onClick={this.handleClick}>Marca</Button>
                                        <Button id='modelo' color='orange' style={{margin: "0 0.25em 0.25em 0"}}
                                        onClick={this.handleClick}>Modelo</Button>
                                        <Button id='linea' color='orange' style={{margin: "0 0.25em 0.25em 0"}}
                                        onClick={this.handleClick}>Línea</Button>
                                        <Button id='equipo' color='orange' style={{margin: "0 0.25em 0.25em 0"}}
                                        onClick={this.handleClick}>Equipo</Button>
                                    </Grid.Row>
                                    <Grid.Row stretched style={{margin: "0.25em"}}>
                                        <Button id='asignar' color='teal' style={{margin: "0 0.25em 0.25em 0"}}
                                        onClick={this.handleClick}>Asignar equipo</Button>
                                        <Button id='historial' color='teal' style={{margin: "0 0.25em 0.25em 0"}}
                                        onClick={this.handleClick}>Descargar Historial</Button>
                                        <Button id='buscar' color='teal' style={{margin: "0 0.25em 0.25em 0"}}
                                        onClick={this.handleClick}>Buscar</Button>
                                    </Grid.Row>
                                </Grid.Row>
                            </Grid.Column>
                                
                            <Grid.Column style={{width: '65%'}}>
                                {
                                    show && registrar && registros.marca &&
                                    <AdministrarMarcas registros={registros}/>
                                }
                                {
                                    show && registrar && registros.modelo &&
                                    <AdministrarModelos registros={registros}/>
                                }
                                {
                                    show && registrar && registros.linea &&
                                    <AdministrarLineas registros={registros}/>
                                }
                                {
                                    show && registrar && registros.equipo &&
                                    <AdministrarEquipos registros={registros}/>
                                }
                                
                                {
                                    show && asignar && !buscar && !historial && !registrar &&
                                    <AdministrarAsignarEquipos asignar={asignar} respuestas={respuestas}/>
                                }
                                {
                                    show && historial && !asignar && !buscar && !registrar &&
                                    <Historial cantidadRegistros={cantidadRegistros}/>
                                }
                                {
                                    show && buscar && !asignar && !historial && !registrar &&
                                    <AdministrarBusquedas buscar={buscar} respuestas={respuestas}/>
                                }
                                
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>
        );
    }
}

export default AdministrarEquiposCelulares;
