import React from 'react';
import { Container, Image, Header } from 'semantic-ui-react';

import logo from '../../images/logo_suma_4.png';

const Home = () => {
    const usuarioId = JSON.parse(localStorage.getItem('usuario'));

    return (
      <Container style={
            { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '5%' }
        }
        >
          <Image src={logo} verticalAlign='bottom' size='medium' />
          <Header as='h3' style={{ color: '#5e6867' }}>{usuarioId.nombre}</Header>
        </Container>
    );
};

export default Home;
