import { combineReducers } from 'redux';

import login from '../reducers/login';
import bitacoras from '../reducers/bitacoras';
import proveedores from '../reducers/proveedores';
import clientes from '../reducers/clientes';
import estructuras from '../reducers/estructuras';
import rutas from '../reducers/rutas';
import operadores from '../reducers/operadores';
import administrativos from '../reducers/administrativos';
import tickets from '../reducers/tickets';
import equiposCelulares from '../reducers/equipos_celulares';
import colaboradores from '../reducers/colaboradores';
import clientes_rutas from '../reducers/clientes_rutas';

const makeRootReducer = () => {
  return combineReducers({
    bitacoras,
    proveedores,
    clientes,
    estructuras,
    rutas,
    operadores,
    administrativos,
    tickets,
    equiposCelulares,
    colaboradores,
    clientes_rutas,
    login
  });
};

export default makeRootReducer;
