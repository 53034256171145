import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';
import axios from 'axios';
import { ENDPOINT_CLIENTES } from '../../constants/endpoints';
import FormaBuscarClientesRutas from './FormaBuscarClientesRutas';

class AdministrarClientesRutas extends Component {
    componentDidMount () {
        const params = ['activo=true', 'id_categoria=1', 'or(id=5)', 'sort(+nombre_empresa)'];
        const url = `${ENDPOINT_CLIENTES}` + (params.length ? `?${params.join('&')}` : '');

        axios.get(url)
            .then(response => {
                const clientes = response.data;

                this.setState({
                    clientes,
                    isLoading: false
                });
            })
            .catch(err => {
                console.log('Error: ', err);
            });
    }

    render () {
        const clientes = this.state;

        return (
          <Container fluid style={{ padding: '10px' }}>
              <h2>Clientes con rutas</h2>
              {
                    clientes &&
                    <FormaBuscarClientesRutas
                      clientes={clientes}
                    />
                }
            </Container>
        );
    }
}

export default AdministrarClientesRutas;
