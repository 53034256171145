import React, { Component } from "react";
import { Button, List, Icon } from "semantic-ui-react";
import ResultadosEmpresarial from "./ResultadosEmpresarial";
import ResultadosEspeciales from "./ResultadosEspeciales";
import ResultadosPorFechas from './ResultadosPorFechas';
import ResultadosTaller from "./ResultadosTaller";
import { downloadExcelPagoOperadores, downloadExcelPagoOperador } from '../../helpers'

class ListaResultados extends Component {
    constructor(props){
        super(props)

        this.state = ({

        })
    }

  render() {
    const listaResultados = this.props.listaResultados
      let totalPorFechas = ''
      let totalEmpresarial = ''
      let totalEspeciales = ''
      let totalTaller = ''
      let listaPorFechas = null
      let listaPorOperador = null
      let sueldoGarantia = null
      let kmTotal = null
      const downloadFile = downloadExcelPagoOperadores;
      const downloadExcelOperador = downloadExcelPagoOperador;

    if(listaResultados.length){
      totalPorFechas = listaResultados.length
      listaPorFechas = listaResultados
    }else{
      totalEmpresarial = listaResultados.EMPRESARIAL.BITACORAS.length
      totalEspeciales = listaResultados.ESPECIALES.BITACORAS.length
      totalTaller = listaResultados.TALLER.BITACORAS.length
      listaPorOperador = listaResultados
      sueldoGarantia = new Intl.NumberFormat('es-MX').format(listaPorOperador.SUELDO_GARANTIA)
      kmTotal = new Intl.NumberFormat('es-MX').format(listaPorOperador.KM_TOTAL)
    }

    return (
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', marginBottom: '15px'}}>
            { totalPorFechas ?
              <div style={{display: 'flex'}}>
                <h3 style={{marginBottom: '20px'}}>
                  Total de registrios encontrados: <span style={{color: '#f2711c'}}>{totalPorFechas}</span>
                </h3>
                <Button size='small' onClick={() => downloadFile(listaPorFechas, 'listaPorFechas')} color='orange' style={{height: 'fit-content', marginLeft: '30px'}}>
                  <List.Icon name='file excel outline' size='large' verticalAlign='middle' />
                  Descargar resultados
                </Button>
              </div>
              :
              <>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h3 style={{marginBottom: '15px'}}>
                  {/* Id: <span style={{color: '#f2711c'}}>{listaPorOperador.ID_OPERADOR}</span>&nbsp;&nbsp;|&nbsp;&nbsp; */}
                  Operador: <span style={{color: '#f2711c'}}>{listaPorOperador.OPERADOR}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                  Empresarial: <span style={{color: '#f2711c'}}>{totalEmpresarial}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                  Especiales: <span style={{color: '#f2711c'}}>{totalEspeciales}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                  Taller: <span style={{color: '#f2711c'}}>{totalTaller}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                  KM total: <span style={{color: '#f2711c'}}>{kmTotal}</span>
                </h3>
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h3 style={{marginBottom: '15px'}}>
                  Sueldo garantía: <span style={{color: '#f2711c'}}>$ {sueldoGarantia}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                  {/* Total pago operador: <span style={{color: '#f2711c'}}>$ {totalPagoGeneral}</span>&nbsp;&nbsp;|&nbsp;&nbsp; */}
                  Días: <span style={{color: '#f2711c'}}>{listaPorOperador.DIAS_TRABAJADOS}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                  Domingo: {listaPorOperador.DOMINGO_TRABAJADO > 0 ? <Icon color="green" name='check' />:<Icon color="red" name='x' />}&nbsp;&nbsp;|&nbsp;&nbsp;
                </h3>
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h3 style={{marginBottom: '15px'}}>
                  Viajes Domingo Empresarial: <span style={{color: '#f2711c'}}>{listaPorOperador.VIAJES_DOMINGO}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                  Pago Domingo Empresarial: <span style={{color: '#f2711c'}}>${listaPorOperador.PAGO_DOMINGO}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                  Bono Domingo Empresarial: <span style={{color: '#f2711c'}}>${listaPorOperador.BONO_DOMINGO}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                </h3>
                <Button size='small' onClick={() => downloadExcelOperador(listaPorOperador, 'listaPorOperador')} color='orange' style={{height: 'fit-content', marginLeft: '30px'}}>
                  <List.Icon name='file excel outline' size='large' verticalAlign='middle' />
                  Descargar resultados
                </Button>
              </div>
              </>
            }
            </div>
        </div>
        
        { listaPorFechas ?
          <ResultadosPorFechas listaPorFechas={listaPorFechas} />:<></>
        }
        
        { !listaPorFechas && listaPorOperador.EMPRESARIAL.BITACORAS.length ?
          <ResultadosEmpresarial listaPorOperador={listaPorOperador} />:<></>
        }

        { !listaPorFechas && listaPorOperador.ESPECIALES.BITACORAS.length ?
          <ResultadosEspeciales listaPorOperador={listaPorOperador} />:<></>
        }

        { !listaPorFechas && listaPorOperador.TALLER.BITACORAS.length ?
          <ResultadosTaller listaPorOperador={listaPorOperador} />:<></>
        }
      </div>
    );
  }
}

export default ListaResultados;