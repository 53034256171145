import React, { Component } from 'react';
import { Table, Segment, Button } from 'semantic-ui-react';
import { downloadExcelHistorialGasolina } from '../../helpers/index'

class Historial extends Component {
  constructor(props){
    super(props)

    this.initialValues = {
        success: null,
    }   

    this.state = {
        ...this.initialValues
    }
  }

  getExcel = () => {
    downloadExcelHistorialGasolina(this.props.historial, 'historialGasolinas')
  }

  render() {
    const { historial } = this.props;

    return (
      <Segment>
        { historial.length > 0 ?
        <>
          <Button id='historial' color='teal' onClick={this.getExcel}>Descargar Historial</Button>
            <Table celled padded striped textAlign='center'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Fecha</Table.HeaderCell>
                  <Table.HeaderCell>Magna</Table.HeaderCell>
                  <Table.HeaderCell>Premium</Table.HeaderCell>
                  <Table.HeaderCell>Diesel</Table.HeaderCell>
                  <Table.HeaderCell>Usuario</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
    
              <Table.Body>
                  { historial.map(item =>
                    <Table.Row key={item.ID}>
                      <Table.Cell singleLine> {item.FECHA ? item.FECHA : 'Sin información'} </Table.Cell>
                      <Table.Cell> {item.MAGNA} </Table.Cell>
                      <Table.Cell> {item.PREMIUM} </Table.Cell>
                      <Table.Cell> {item.DIESEL} </Table.Cell>
                      <Table.Cell> {item.NOMBRE} </Table.Cell>
                      {/* <Table.Cell> {item.MAGNA ? item.MAGNA : 'No actualizado'} </Table.Cell>
                      <Table.Cell> {item.PREMIUM ? item.PREMIUM : 'No actualizado'} </Table.Cell>
                      <Table.Cell> {item.DIESEL ? item.DIESEL : 'No actualizado'} </Table.Cell>
                      <Table.Cell> {item.NOMBRE ? item.NOMBRE : 'No actualizado'} </Table.Cell> */}
                    </Table.Row>
                  )}
              </Table.Body>
            </Table>
          </>:<><h3>Sin registros en historial</h3></>
        }
      </Segment>
    );
  }
}

export default Historial;