import React, { Component } from "react";
import { Segment, Form, Select, Button, Message } from "semantic-ui-react";
import axios from "axios";
import ListaResultados from "./ListaResultados";

class ResultadosForm extends Component {
    constructor(props){
        super(props)

        this.initialValues = {
            loading: false,
            mensaje: false,
            mostrarLista: false,
            idOperador: '',
            fecha_fin: '',
            fecha_inicio: '',
            listaResultados: null
        }
      
        this.state = {
        ...this.initialValues
        }
        
    }
    
    componentDidMount(){
        //Testing
        //const url = "http://tapi.sumaenlinea.mx/operadores?activo=true&sort(+nombre)";
        //Producción
        const url = "https://api.sumaenlinea.mx/operadores?activo=true&sort(+nombre)";
        // const url = "https://tapi2.sumaenlinea.mx/operadores?activo=true&sort(+nombre)";

        this.setState({
            loading: true
        })

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200){

                        const operadores = response.data.map(op => {
                            return { key: op.data.id, text: op.data.attributes.nombre, value: op.data.id }
                        })

                        return this.setState({
                            operadores: operadores,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        )
    }

    handleChangeValue = (e, data) => {
        this.setState({ [data.name]: data.value })
    }
    cambiarFormatoFecha = (fecha)=> {
        // Separar la fecha en año, mes y día
        const [year, month, day] = fecha.split('-');
        
        // Devolver la fecha en formato dd-MM-yyyy
        return `${day}-${month}-${year}`;
    }
    verPagoOperadores = () => {
        const id_operador = this.state.idOperador;
        const fecha_inicio = this.state.fecha_inicio ;
        const fecha_fin = this.state.fecha_fin;

        let url = ''

        if(!fecha_inicio || !fecha_fin){
            return this.setState({
                mensaje: true
            })
        }
        
        if(!id_operador && fecha_inicio && fecha_fin){
            //url = `http://tapi.sumaenlinea.mx/bitacoras/getBitacorasOperador?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}`;
            url = `https://api.sumaenlinea.mx/bitacoras/getBitacorasOperador?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}`;
            // url = `https://tapi2.sumaenlinea.mx/bitacoras/getBitacorasOperador?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}`;
        }

        if(id_operador && fecha_inicio && fecha_fin){
            //url = `http://tapi.sumaenlinea.mx/bitacoras/getBitacorasOperador?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}&id_operador=${id_operador}`;
            url = `https://api.sumaenlinea.mx/bitacoras/getBitacorasOperador?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}&id_operador=${id_operador}`;
            // url = `https://tapi2.sumaenlinea.mx/bitacoras/getBitacorasOperador?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}&id_operador=${id_operador}`;
        }

        
        this.setState({
            loading: true
        })

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200 && response.data.data && response.data.success === true){
                        return this.setState({
                            listaResultados: response.data.data,
                            mostrarLista: true,
                            sinResultados: false,
                            loading: false,
                            idOperador: '',
                            fecha_inicio: '',
                            fecha_fin: '',
                            mensaje: false,
                        });
                    }else{
                        return this.setState({
                            sinResultados: response.data,
                            mostrarLista: false,
                            loading: false,
                            idOperador: '',
                            fecha_inicio: '',
                            fecha_fin: '',
                            mensaje: false
                        }); 
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        )
    }

    onReset = (event) => {
        event.preventDefault()

        this.setState({
            ...this.initialValues
        })
    }
    
    

  render() {
    const operadores = this.state?.operadores;
    const { idOperador, mostrarLista, listaResultados, 
        mensaje, sinResultados, fecha_fin, fecha_inicio    
        } = this.state;
       
    return (
        <>
        <Segment.Group>
            <Segment>
                <h3>Buscar pago a operadores</h3>
                <Form>
                    <Form.Group widths='equal' className='form-group-inputs'>
                        <Form.Field
                        control={Select}
                        options={operadores}
                        label='Operador'
                        placeholder='Operador'
                        search
                        value={idOperador}
                        name='idOperador'
                        onChange={this.handleChangeValue}
                        loading={this.state.loading}
                        />
                        <Form.Input
                        fluid={true}
                        label="Desde"
                        name='fecha_inicio'
                        type="date"
                        value={fecha_inicio}
                        onChange={this.handleChangeValue}
                        />
                        <Form.Input
                        fluid={true}
                        label="Hasta"
                        name='fecha_fin'
                        type="date"
                        value={fecha_fin}
                        onChange={this.handleChangeValue}
                        />
                    </Form.Group>
                    <Button  className='form-button' color='orange' onClick={this.verPagoOperadores}>Ver resultados</Button>
                    <Button onClick={this.onReset}>Limpiar</Button>
                </Form>
            </Segment>
        </Segment.Group>

        { mostrarLista && listaResultados ?
        <ListaResultados listaResultados={listaResultados}/>
        :<></>
        }

        { sinResultados ?
            <Message info style={{textAlign: 'left'}}>
                <Message.Header>{sinResultados.data}</Message.Header>
            </Message>
            : <></>
        }

        { mensaje && !mostrarLista && !sinResultados && !listaResultados ?
            <Message info style={{textAlign: 'left'}}>
                <Message.Header>Datos faltantes</Message.Header>
                <p>Es necesario seleccionar un rango de fechas</p>
            </Message>
            : <></>
        }
        </>
    );
  }
}

export default ResultadosForm;