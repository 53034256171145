import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import ResultadosForm from "./ResultadosForm";

class Encuestas extends Component {
    constructor(props){
        super(props)

        this.state = ({
            
        })
    }

  render() {

    return (
      <Container fluid style={{ padding: "10px" }}>
        <ResultadosForm />
      </Container>
    );
  }
}

export default Encuestas;