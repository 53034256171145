import React, { Component } from 'react';
import { Table, Button, Container, Modal } from 'semantic-ui-react';

import AdministrarAsignarEquipos from './AdministrarAsignar';

class ListadoResultados extends Component {
  constructor(props){
    super(props)

    this.initialValues = {
        success: null,
        abrirAsignar: false,
    }   

    this.state = {
        ...this.initialValues
    }
  }
  
  onEdit = () => {
    this.setState({
      ...this.initialValues,
      asignar: true,
    })
    this.onOPen()
  }

  onOPen = () => {
    this.setState({
      abrirAsignar: true,
    })
  }

  onClose = () => {
    this.setState({
      abrirAsignar: false,
    })
  }

  render() {
    const { lista, respuestas } = this.props;
    const { abrirAsignar, asignar } = this.state;

    return (
      <>
      {
        lista ?
        <Container>
        <Table celled padded striped textAlign='center'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Nombre</Table.HeaderCell>
              <Table.HeaderCell>Correo</Table.HeaderCell>
              <Table.HeaderCell>IMEI</Table.HeaderCell>
              <Table.HeaderCell>Modelo</Table.HeaderCell>
              <Table.HeaderCell>Línea</Table.HeaderCell>
              <Table.HeaderCell>Acciones</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {lista.map(item =>
              <Table.Row key={item.ID}>
                <Table.Cell singleLine> {item.ID} </Table.Cell>
                <Table.Cell> {item.NOMBRE} </Table.Cell>
                <Table.Cell> {item.EMAIL.trim() ? item.EMAIL.trim() : "Sin dato"} </Table.Cell>
                <Table.Cell> {item.IMEI} </Table.Cell>
                <Table.Cell> {item.MODELO_NOMBRE} </Table.Cell>
                <Table.Cell> {item.TELEFONO} </Table.Cell>
                <Table.Cell>
                  <Button
                    basic color='orange'
                    icon='edit'
                    onClick={() => {
                      this.onEdit(item.ID);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
     
        {
          abrirAsignar == true ?
          <Modal
            open={abrirAsignar}
            dimmer= 'blurring'
            size= 'tiny'
          >
          <Modal.Content>
            <AdministrarAsignarEquipos asignar={asignar} respuestas={respuestas}/>
          </Modal.Content>
          <Modal.Actions>
              <Button onClick={this.onClose}>Cerrar</Button>
            </Modal.Actions>
          </Modal>:<></>
        }
        
      </Container>
      :<></>
      }
      </>
    );
  }
}

export default ListadoResultados;
