import React, { Component } from 'react'
import { Form, Message, Grid, Button, Container } from 'semantic-ui-react'
import axios from 'axios';
import { ENDPOINT_MARCAS_CELULARES } from '../../constants/endpoints';

import { extraerOpcionesMarcasEquiposCelulares } from '../../helpers/index'

class AdministrarMarcas extends Component {
    constructor(props){
        super(props)

        this.initialValues = {
            registrar: false,
            editar: false,
            cerrar: false,
            marca: '',
            success: null
        }   

        this.state = {
            ...this.initialValues
        }
    }

    getMarcas () {
        const url = `${ENDPOINT_MARCAS_CELULARES}` + 'lista';
        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        marcas: response.data.data,
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    handleClickButton = (e, data) => {
        switch (data.id) {
            case 'registrar':
                this.setState({
                    ...this.initialValues,
                    registrar: true,
                })
                break;
            case 'editar':
                this.getMarcas()
                this.setState({
                    ...this.initialValues,
                    editar: true,
                })
                break;
            default:
                this.setState({
                    ...this.initialValues,
                })
                break;
        }
    }

    onChange = (e, data) => {
        if (data) {
            this.setState({ [data.name]: data.value })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    onSubmit = () => {
        const params = [];

        if(this.state.marca){
            params.push(`marca=${this.state.marca}`)
        }

        const url = `${ENDPOINT_MARCAS_CELULARES}` + 'add' + (params.length ? `?${params.join('&')}` : '');

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        success: response.data.success,
                        message: response.data.message,
                        details: response.data.details
                    })

                    setTimeout(() => this.setState({...this.initialValues }), 3000);
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    onEdit= () => {
        const params = [];

        if(this.state.nuevoNombre){
            params.push(`marca=${this.state.nuevoNombre}`)
        }

        if(this.state.marcaSeleccionada){
            params.push(`id=${this.state.marcaSeleccionada}`)
        }

        const url = `${ENDPOINT_MARCAS_CELULARES}` + 'modificar' + (params.length ? `?${params.join('&')}` : '');

        axios.post(url)
            .then(response => {
                if(response.status == 200){
                    this.setState({
                        success: response.data.success,
                        message: response.data.message,
                        details: response.data.details
                    })

                    setTimeout(() => this.setState({...this.initialValues }), 3000);
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    render () {
        const { registros } = this.props
        const { marca, nuevoNombre, marcas, registrar, editar, success, error, message, details } = this.state
        
        return(
            <>
                <Container>
                    <Grid columns={2} relaxed='very' stackable>
                        <Grid.Column>
                            {
                                registros.marca ?
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <Button id='registrar' color='green' onClick={this.handleClickButton} size='medium' style={{marginBottom: '10px'}}>Registrar marca</Button>
                                    <Button id='editar' color='green' onClick={this.handleClickButton} size='medium'>Editar marca</Button>
                                </div>:<></>
                            }
                        </Grid.Column>
                        <Grid.Column>
                            {
                                registrar ?
                                <>
                                <h3>Registrar Marca</h3>
                                <Form form='formRegistrarMarcas' onSubmit={this.onSubmit}>
                                    <Form.Input 
                                        label='Ingresa el nombre de la marca' 
                                        name='marca'
                                        value={this.state.marca}
                                        onChange={this.onChange}
                                        placeholder='Marca'
                                        required
                                    />
                                    <Form.Field>
                                        <Form.Button id='guardar' color='orange'>Registrar</Form.Button>
                                    </Form.Field>
                                </Form></>:<></>
                            }
                            {
                                success === true && registrar ? (
                                    <Message positive>
                                        <Message.Header>Marca {marca}</Message.Header>
                                        <Message.Content>Registrada correctamente</Message.Content>
                                    </Message>
                                ):(
                                    <></>
                                )
                            }

                            {
                                success === false  && registrar ? (
                                    <Message error header={message} list={details}/>
                                ):(
                                <></> 
                                )
                            }

                            { marcas && editar ? 
                                <>
                                 <h3>Editar Marca</h3>
                                 <Form form='formEditarMarcas' onSubmit={this.onEdit}>
                                    <Form.Select
                                        name='marcaSeleccionada' label='Selecciona la marca' fluid search selection
                                        options={marcas.map(extraerOpcionesMarcasEquiposCelulares)}
                                        value={marcas.ID}
                                        placeholder='Marca'
                                        onChange={this.onChange}
                                    />
                                    <Form.Input 
                                        label='Ingresa el nuevo nombre de la marca' 
                                        name='nuevoNombre'
                                        value={nuevoNombre}
                                        placeholder='Nuevo nombre de la marca'
                                        onChange={this.onChange}
                                        required
                                    />
                                    <Form.Field>
                                        <Form.Button id='editar' color='orange'
                                            onClick={this.handleClickButton}>Editar</Form.Button>
                                    </Form.Field>
                                 </Form>
                                </>:<></>
                            }

                            {
                                success === true && editar ? (
                                    <Message positive>
                                        <Message.Header>Nuevo nombre de marca {nuevoNombre}</Message.Header>
                                        <Message.Content>Editada correctamente</Message.Content>
                                    </Message>
                                ):(
                                    <></>
                                )
                            }

                            {
                                success === false  && editar ? (
                                    <Message error header={message} list={details}/>
                                ):(
                                <></> 
                                )
                            }
                            
                        </Grid.Column>
                    </Grid>

                    {
                        error &&
                        <Message negative>
                            <Message.Header>Error ({error})</Message.Header>
                        {error}
                        </Message>
                    }
                </Container>
            </>
        )
    }
}

export default AdministrarMarcas