import { connect } from 'react-redux';
import { requestProveedores } from '../../creators/proveedores';
import { requestOperadores } from '../../creators/operadores';
import { requestAdministrativos } from '../../creators/administrativos';
import { requestBuscarTickets, iniciarEdicionTicket } from '../../creators/tickets';
import FormaBuscarTickets from '../../components/AdministrarTickets/FormaBuscarTickets';

const mapStateToProps = ({ operadores, administrativos, proveedores, tickets }) => {
    return {
        operadores,
        administrativos,
        proveedores,
        tickets
    };
};

const mapActionsToProps = {
    requestBuscarTickets,
    requestOperadores,
    requestAdministrativos,
    requestProveedores,
    iniciarEdicionTicket
};

export default connect(mapStateToProps, mapActionsToProps)(FormaBuscarTickets);
